import { ReactNode } from 'react';
import styled from 'styled-components';
import { UnknownFunction } from '../../types';
import { Portal } from '../portal';
import { XMarkIcon } from '../../icons/x-mark-icon';
import { triggerHaptic } from '../../utils';

const ModalWrapper = styled.div`
  z-index: 99999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
`;

const ModalCard = styled.div`
  position: relative;
  width: calc(100% - 16px);
  margin-bottom: 16px;
  padding: 24px;
  background: repeating-linear-gradient(-45deg, #000, #000 3px, #252525 3px, #252525 6px);

  &::before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 10px;
    left: 10px;
    display: block;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    background-color: #000;
  }

  &::after {
    content: '';
    z-index: 1;
    position: absolute;
    bottom: 40px;
    left: 0;
    display: block;
    width: 10px;
    height: 40px;
    background-color: #000;
  }
`;

const ModalTitle = styled.div`
  position: relative;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding-bottom: 16px;
  text-align: center;

  h4 {
    font-size: 24px;
    font-weight: 700;
  }

  label {
    color: #909090;
  }
`;

const ModalIcon = styled.div`
  overflow: hidden;
  width: 64px;
  height: 64px;
  margin: 24px auto;
  border: 2px solid var(--Color-Blue, #32c5f3);
  transform: rotate(-45deg);

  img {
    width: 88px;
    min-width: 88px;
    margin-top: -14px;
    margin-left: -14px;
    transform: rotate(45deg);
  }
`;

const ModalClose = styled.div`
  z-index: 100;
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;

const ModalContent = styled.div`
  position: relative;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ModalBottom = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 10;
  padding: 32px;
`;

export const ButtonsSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

const MODAL_ROOT_ID = 'modal-root';

export function ModalRoot() {
  return <div id={MODAL_ROOT_ID} />;
}

interface ModalProps {
  children: ReactNode;
  onClose?: UnknownFunction;
  title?: string;
  descriptionTitle?: string;
  bottomSection?: ReactNode;
}

export function Modal({ children, onClose, title, descriptionTitle, bottomSection }: ModalProps) {
  return (
    <Portal targetId={MODAL_ROOT_ID}>
      <ModalWrapper onClick={onClose}>
        <ModalCard onClick={(e) => e.stopPropagation()}>
          {onClose && (
            <ModalClose
              onClick={() => {
                onClose();
                triggerHaptic();
              }}>
              <XMarkIcon />
            </ModalClose>
          )}

          <ModalTitle>
            <ModalIcon>
              <img src={'./assets/img/modal-icon.png'} alt="" />
            </ModalIcon>
            {title && <h4>{title}</h4>}
            {descriptionTitle && <label>{descriptionTitle}</label>}
          </ModalTitle>
          <ModalContent>{children}</ModalContent>
          {bottomSection && <ModalBottom>{bottomSection}</ModalBottom>}
        </ModalCard>
      </ModalWrapper>
    </Portal>
  );
}
