import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MainPage, RanksPage, RewardPage, TasksPage, TeamPage, UpgradePage } from '../pages';
import { Layout } from './layout';

declare global {
  interface Window {
    Telegram: any;
  }
}

export function AppRoutes() {
  const tg = window.Telegram.WebApp;
  tg.expand();

  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<Layout />}>
          <Route index path={'/'} element={<MainPage />} />
          <Route path={'/upgrade'} element={<UpgradePage />} />
          <Route path={'/team'} element={<TeamPage />} />
          <Route path={'/tasks'} element={<TasksPage />} />
          <Route path={'/reward'} element={<RewardPage />} />

          <Route index path={'/ranks'} element={<RanksPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
