import styled from 'styled-components';
import { Container } from './layout';
import { triggerHaptic } from '../utils';

interface WrapperProps {
  itemsTotal: number;
  isFull?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: grid;
  grid-template-columns: repeat(${({ itemsTotal }) => itemsTotal}, 1fr);
  gap: 24px;
  width: ${({ isFull }) => (isFull ? '100%' : 'fit-content')};
  height: 48px;
  margin: 0 auto;
  padding: 0 24px;
  border: 1px solid #404040;
  border-radius: 8px;
  background-color: #111;
`;

interface ItemProps {
  isActive: boolean;
}

const Item = styled.div<ItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${({ isActive }) => (isActive ? '#32C5F3' : '#F0F0F0')};
  font-weight: ${({ isActive }) => (isActive ? '800' : '400')};
`;

type BarProps<T> = {
  list: Record<string, string>;
  value: T;
  setValue: (newValue: T) => void;
  isFull?: boolean;
};

export function Bar<T>({ list, value, setValue, isFull }: BarProps<T>) {
  return (
    <Container>
      <Wrapper itemsTotal={Object.entries(list).length} isFull={isFull}>
        {Object.entries(list).map((text, i) => (
          <Item
            key={i}
            isActive={text[0] === value}
            onClick={() => {
              triggerHaptic();
              setValue(text[0] as T);
            }}>
            {text[1]}
          </Item>
        ))}
      </Wrapper>
    </Container>
  );
}
