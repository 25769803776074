export function IconTasks() {
  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector"
          d="M16.4638 0.75H7.03625C2.94125 0.75 0.5 3.19125 0.5 7.28625V16.7138C0.5 20.8088 2.94125 23.25 7.03625 23.25H16.4638C20.5588 23.25 23 20.8088 23 16.7138V7.28625C23 3.19125 20.5588 0.75 16.4638 0.75ZM9.46625 15.2625L6.935 17.7937C6.76625 17.9625 6.5525 18.0413 6.33875 18.0413C6.125 18.0413 5.9 17.9625 5.7425 17.7937L4.89875 16.95C4.56125 16.6237 4.56125 16.0837 4.89875 15.7575C5.225 15.4313 5.75375 15.4313 6.09125 15.7575L6.33875 16.005L8.27375 14.07C8.6 13.7438 9.12875 13.7438 9.46625 14.07C9.7925 14.3962 9.7925 14.9362 9.46625 15.2625ZM9.46625 7.3875L6.935 9.91875C6.76625 10.0875 6.5525 10.1663 6.33875 10.1663C6.125 10.1663 5.9 10.0875 5.7425 9.91875L4.89875 9.075C4.56125 8.74875 4.56125 8.20875 4.89875 7.8825C5.225 7.55625 5.75375 7.55625 6.09125 7.8825L6.33875 8.13L8.27375 6.195C8.6 5.86875 9.12875 5.86875 9.46625 6.195C9.7925 6.52125 9.7925 7.06125 9.46625 7.3875ZM18.005 17.1975H12.0988C11.6375 17.1975 11.255 16.815 11.255 16.3538C11.255 15.8925 11.6375 15.51 12.0988 15.51H18.005C18.2288 15.51 18.4434 15.5989 18.6016 15.7571C18.7599 15.9154 18.8487 16.13 18.8487 16.3538C18.8487 16.5775 18.7599 16.7921 18.6016 16.9504C18.4434 17.1086 18.2288 17.1975 18.005 17.1975ZM18.005 9.3225H12.0988C11.6375 9.3225 11.255 8.94 11.255 8.47875C11.255 8.0175 11.6375 7.635 12.0988 7.635H18.005C18.2288 7.635 18.4434 7.7239 18.6016 7.88213C18.7599 8.04036 18.8487 8.25497 18.8487 8.47875C18.8487 8.70253 18.7599 8.91714 18.6016 9.07537C18.4434 9.23361 18.2288 9.3225 18.005 9.3225Z"
          fill="#F0F0F0"
        />
      </g>
    </svg>
  );
}
