import { SDKProvider } from '@telegram-apps/sdk-react';
import { AppRoutes } from './app-routes';
import { QueryClient, QueryClientProvider } from 'react-query';

export function App() {
  const queryClient = new QueryClient();

  return (
    <SDKProvider debug>
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
      </QueryClientProvider>
    </SDKProvider>
  );
}
