import styled from 'styled-components';
import { UnknownFunction } from '../../types';
import { calculateTimeLeft, triggerHaptic } from '../../utils';
import { useEffect, useState } from 'react';
import { ExitMiniGameModal } from '../modals';

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 3fr;
  gap: 8px;

  padding: 12px;
  border-radius: 8px;
  border: 1px solid #404040;
  background: #111;
`;

const Button = styled.button`
  aspect-ratio: 1;
  border: 1px solid rgba(64, 64, 64, 1);
  border-radius: 10px;
  background: linear-gradient(rgba(64, 64, 64, 1), rgba(34, 34, 34, 1));
  box-shadow: 0 4px 2px -2px #000;

  font-size: 20px;
  font-weight: 600;
  color: #fff;
`;

const TimeBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 12px;
`;

const TimeBarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`;

interface ProgressLineProps {
  width: number;
  timeTotal: number;
}

const LineWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 24px;
`;

const Line = styled.div<ProgressLineProps>`
  width: 100%;
  height: 100%;
  background-color: ${({ width }) => {
    if (width > 66.66) return '#32c5f3';
    if (width > 33.33) return '#F3A632';
    return '#D23C3C';
  }};
  background-image: linear-gradient(
      45deg,
      black 25%,
      transparent 25%,
      transparent 74%,
      black 75%,
      black
    ),
    linear-gradient(45deg, black 25%, transparent 25%, transparent 74%, black 75%, black);
  background-size: 8px 8px;
  background-position:
    0 0,
    4px 4px;
  opacity: 0.4;
`;

const ProgressLine = styled.div<ProgressLineProps>`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ width }) => {
    if (width > 66.66) return '#32c5f3';
    if (width > 33.33) return '#F3A632';
    return '#D23C3C';
  }};
  animation: progress ${({ timeTotal }) => timeTotal}s linear;

  @keyframes progress {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
`;

interface MiniGameHeaderProps {
  timeLeft: number;
  startedAt: Date;
  isPassed: boolean;
  onClose: UnknownFunction;
  setIsExpired: (value: boolean) => void;
}

export function MiniGameHeader({
  timeLeft,
  startedAt,
  isPassed,
  onClose,
  setIsExpired,
}: MiniGameHeaderProps) {
  const [secondsLeft, setSecondsLeft] = useState(calculateTimeLeft(startedAt, timeLeft));
  const [isModal, setIsModal] = useState(false);

  const minutes = Math.floor(secondsLeft / 60);
  const seconds = secondsLeft % 60;
  const percentLeft: number = (secondsLeft / timeLeft) * 100;

  const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  useEffect(() => {
    if (secondsLeft > 0 && !isPassed) {
      const timer = setInterval(() => {
        setSecondsLeft((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            return 0;
          }
        });
      }, 1000);
      return () => clearInterval(timer);
    }
    if (secondsLeft === 0) setIsExpired(true);
  }, [secondsLeft, isPassed]);

  useEffect(() => {
    document.addEventListener('visibilitychange', () =>
      setSecondsLeft(calculateTimeLeft(startedAt, timeLeft))
    );

    return () => {
      document.removeEventListener('visibilitychange', () =>
        setSecondsLeft(calculateTimeLeft(startedAt, timeLeft))
      );
    };
  }, []);

  return (
    <Wrapper>
      <Button
        onClick={() => {
          triggerHaptic();
          setIsModal(true);
        }}>
        esc
      </Button>

      <TimeBar>
        <TimeBarHeader>
          <div>TIME REMAIN</div>
          <div>{formattedTime}</div>
        </TimeBarHeader>
        <LineWrapper>
          <Line width={percentLeft} timeTotal={timeLeft} />
          <ProgressLine width={percentLeft} timeTotal={timeLeft} />
        </LineWrapper>
      </TimeBar>

      {isModal && <ExitMiniGameModal onClose={() => setIsModal(false)} onGameClose={onClose} />}
    </Wrapper>
  );
}
