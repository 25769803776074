import styled from 'styled-components';
import { Container, Task } from '../components';
import { Api } from '../api';
import { useEffect } from 'react';

const Title = styled.div`
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
`;

const TasksList = styled.div`
  margin-top: 36px;

  &:first-child {
    margin-top: 0;
  }
`;

export function TasksPage() {
  const getTasks = async () => {
    await Api.getTasks();
  };

  useEffect(() => {
    getTasks();
  }, []);

  return (
    <>
      <Container>
        <TasksList>
          <Title>Daily tasks</Title>
          <Task />
        </TasksList>

        <TasksList>
          <Title>Tasks list</Title>
          <Task />
          <Task />
        </TasksList>
      </Container>
    </>
  );
}
