export function IconGame() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_263_17858)">
        <path
          d="M20 14.2101C19.9531 11.6008 19.6449 8.9621 19.1394 6.29882C18.7277 4.44843 17.2464 2.80038 14.8796 2.67968C13.1324 2.6078 12.7945 3.60312 10.8148 3.58398C10.2726 3.58046 9.73081 3.58046 9.18863 3.58398C7.20855 3.60312 6.86988 2.6078 5.12339 2.67968C2.75621 2.80038 1.22886 4.44413 0.862065 6.29882C0.356206 8.9621 0.0480026 11.6004 0.00151824 14.2098C-0.00980989 16.0266 1.78394 17.2351 2.96753 17.318C5.25386 17.4906 7.07027 13.459 8.45425 13.4586C9.48589 13.4644 10.5171 13.4648 11.5488 13.4586C12.9332 13.4586 14.748 17.491 17.0359 17.3184C18.2191 17.2355 20.0593 16.0187 20.0003 14.2101H20ZM7.45855 9.11874H6.39214V10.1851C6.39214 10.6351 6.0273 11 5.5773 11C5.1273 11 4.76245 10.6351 4.76245 10.1851V9.11874H3.69605C3.24605 9.11874 2.88121 8.7539 2.88121 8.3039C2.88121 7.8539 3.24605 7.48905 3.69605 7.48905H4.76245V6.42265C4.76245 5.97265 5.1273 5.6078 5.5773 5.6078C6.0273 5.6078 6.39214 5.97265 6.39214 6.42265V7.48905H7.45855C7.90855 7.48905 8.27339 7.8539 8.27339 8.3039C8.27339 8.7539 7.90855 9.11874 7.45855 9.11874ZM14.0433 10.9996C13.4054 11.0168 12.875 10.5129 12.8578 9.87538C12.841 9.23554 13.3453 8.70429 13.9828 8.68788C14.621 8.67187 15.1523 9.17538 15.1687 9.81366C15.1847 10.4519 14.6812 10.9832 14.0433 10.9996ZM15.9671 7.91952C15.3293 7.93749 14.7976 7.43319 14.78 6.7953C14.764 6.15624 15.2675 5.62577 15.9058 5.60819C16.5449 5.5914 17.0753 6.09569 17.0925 6.73398C17.1093 7.37187 16.6046 7.90351 15.9671 7.91952Z"
          fill="#F0F0F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_263_17858">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
