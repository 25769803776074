export function IconReward() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12.2501 7.74995H12.0001C11.8824 7.74516 11.7673 7.71306 11.6641 7.65618C11.5609 7.5993 11.4724 7.5192 11.4055 7.42221C11.3385 7.32522 11.2951 7.21399 11.2785 7.09732C11.262 6.98065 11.2728 6.86173 11.3101 6.74995C11.6601 5.86995 11.9801 4.96995 12.2401 4.08995C12.4601 3.40995 13.3201 1.27995 15.4801 1.27995C16.3219 1.25298 17.1401 1.56107 17.755 2.13663C18.3699 2.71218 18.7314 3.50818 18.7601 4.34995C18.7601 6.27995 16.5701 7.06995 15.7601 7.34995C14.6149 7.64878 13.4331 7.78346 12.2501 7.74995ZM15.4701 2.74995C14.3201 2.74995 13.8001 4.07995 13.6701 4.48995C13.4901 5.06995 13.3001 5.64995 13.0901 6.22995C13.807 6.2254 14.5201 6.1245 15.2101 5.92995C15.7801 5.72995 17.2101 5.18995 17.2101 4.31995C17.1793 3.88408 16.9809 3.47708 16.6564 3.18435C16.332 2.89163 15.9068 2.73594 15.4701 2.74995Z"
        fill="#32C5F3"
      />
      <path
        d="M11.72 7.75001C10.5627 7.7704 9.40824 7.62903 8.29004 7.33001C5.80004 6.52001 5.29004 5.25001 5.29004 4.33001C5.29422 3.94143 5.377 3.55771 5.53339 3.20197C5.68978 2.84622 5.91655 2.5258 6.20004 2.26001C6.83044 1.62583 7.68587 1.26641 8.58004 1.26001C10.26 1.26001 11.11 2.83001 11.74 3.95001C12.1495 4.86815 12.4839 5.81791 12.74 6.79001C12.771 6.90032 12.7763 7.01627 12.7555 7.12894C12.7347 7.24162 12.6884 7.34803 12.62 7.44001C12.5538 7.53289 12.467 7.60921 12.3664 7.66298C12.2658 7.71676 12.1541 7.74654 12.04 7.75001H11.72ZM8.47004 2.75001C7.99516 2.77046 7.54687 2.97488 7.22004 3.32001C7.0775 3.44571 6.96224 3.5993 6.88141 3.7713C6.80057 3.9433 6.75586 4.13005 6.75004 4.32001C6.75004 4.93001 7.49004 5.52001 8.75004 5.93001C9.48526 6.12005 10.2407 6.22078 11 6.23001C10.8383 5.69746 10.6412 5.17631 10.41 4.67001L11 4.31001L10.35 4.67001C9.77004 3.56001 9.27004 2.77001 8.50004 2.75001H8.47004Z"
        fill="#32C5F3"
      />
      <path
        d="M21.25 10.25V9C21.25 8.27065 20.9603 7.57118 20.4445 7.05546C19.9288 6.53973 19.2293 6.25 18.5 6.25H5.5C4.77065 6.25 4.07118 6.53973 3.55546 7.05546C3.03973 7.57118 2.75 8.27065 2.75 9V10.25H21.25ZM12.75 11.75V22.75H18.5C19.2293 22.75 19.9288 22.4603 20.4445 21.9445C20.9603 21.4288 21.25 20.7293 21.25 20V11.75H12.75ZM11.25 11.75H2.75V20C2.75 20.7293 3.03973 21.4288 3.55546 21.9445C4.07118 22.4603 4.77065 22.75 5.5 22.75H11.25V11.75Z"
        fill="#32C5F3"
      />
    </svg>
  );
}
