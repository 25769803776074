export function IconRank() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_278_14663)">
        <path
          d="M5.41179 19.4118H1.17649C0.882374 19.4118 0.588257 19.1177 0.588257 18.8235V14.5294C0.588257 14.1765 0.882374 13.9412 1.17649 13.9412H5.29414V18.8235C5.29414 19 5.35296 19.2353 5.41179 19.4118ZM13.5294 11.7647V18.8235C13.5294 19.1177 13.2353 19.4118 12.9412 19.4118H7.05885C6.76473 19.4118 6.47061 19.1177 6.47061 18.8235V11.7647C6.47061 11.4118 6.76473 11.1765 7.05885 11.1765H12.9412C13.2353 11.1765 13.5294 11.4118 13.5294 11.7647ZM19.4118 15.2941V18.8235C19.4118 19.1177 19.1177 19.4118 18.8236 19.4118H14.5883C14.6471 19.2353 14.7059 19 14.7059 18.8235V14.7059H18.8236C19.1177 14.7059 19.4118 14.9412 19.4118 15.2941ZM14.7647 4.7059L12.8236 6.58825L13.2941 9.29414C13.353 9.52943 13.2353 9.76472 13.0588 9.88237C12.8824 10 12.5883 10 12.4118 9.94119L10 8.64708L7.58826 9.88237C7.41179 10 7.17649 10 7.00002 9.82355C6.82355 9.7059 6.7059 9.47061 6.76473 9.23531L7.23532 6.52943L5.23532 4.7059C5.11767 4.58825 5.05885 4.35296 5.11767 4.11767C5.17649 3.88237 5.35296 3.76472 5.58826 3.7059L8.29414 3.29414L9.47061 0.882371C9.64708 0.470606 10.353 0.470606 10.5294 0.882371L11.7059 3.29414L14.4118 3.7059C14.6471 3.76472 14.8236 3.88237 14.8824 4.11767C14.9412 4.35296 14.8824 4.58825 14.7647 4.7059Z"
          fill="#F0F0F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_278_14663">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
