import styled from 'styled-components';
import { IconCoinDarknet } from '../../icons';
import { UnknownFunction } from '../../types';
import { getBalance } from '../../utils';
import { Button, Modal } from '../UI';

const Coins = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 24px;
  font-weight: 700;
`;

interface OfflineProfitModalProps {
  // hours: number;
  // earned: number;
  onClose: UnknownFunction;
}

export function OfflineProfitModal({ onClose }: OfflineProfitModalProps) {
  return (
    <Modal
      title={'Offline profit'}
      descriptionTitle={'Earn offline up to 6 hours'}
      bottomSection={
        <Button bgColor={'#000'} onClick={onClose}>
          Collect
        </Button>
      }>
      <Coins>
        <IconCoinDarknet />
        <span>+{getBalance(100)}</span>
      </Coins>
    </Modal>
  );
}
