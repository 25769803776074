export const formatTimerTime = (totalSeconds: number): string => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  const formatted = [
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0'),
  ]
    .filter((time, index) => time !== '00' || index > 0)
    .join(':');
  return formatted;
};

export function getRemainingSeconds(totalSeconds: number, startAt: Date): number {
  const now = new Date();

  const elapsedMilliseconds = now.getTime() - startAt.getTime();
  const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
  const remainingSeconds = totalSeconds - elapsedSeconds;

  return remainingSeconds > 0 ? remainingSeconds : 0;
}
