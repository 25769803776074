import styled from 'styled-components';
import { AnimatedNumber } from '../animation';

const ProgressBarWrapper = styled.div`
  padding: 0 34px;
`;

export const ProgressBarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  font-size: 14px;
`;

const Line = styled.div`
  position: relative;
  width: 100%;
  height: 24px;
  background-color: #2d4038;
  background-image: linear-gradient(
      45deg,
      black 25%,
      transparent 25%,
      transparent 74%,
      black 75%,
      black
    ),
    linear-gradient(45deg, black 25%, transparent 25%, transparent 74%, black 75%, black);
  background-size: 10px 10px;
  background-position:
    0 0,
    5px 5px;
`;

interface LineProgressProps {
  anime: number;
}

const LineProgress = styled.div<LineProgressProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: ${({ anime }) => {
    if (anime === 1) return '30%';
    if (anime === 2) return '70%';
    return '100%';
  }};
  height: 100%;
  background-color: #32c5f3;
  animation: ${({ anime }) => `Progress${anime}`} 1s linear;

  @keyframes Progress1 {
    from {
      width: 0;
    }
    to {
      width: 30%;
    }
  }

  @keyframes Progress2 {
    from {
      width: 30%;
    }
    to {
      width: 70%;
    }
  }

  @keyframes Progress3 {
    from {
      width: 70%;
    }
    to {
      width: 100%;
    }
  }
`;

interface ProgressBarProps {
  size: number;
  anime: number;
}

export function ProgressBar({ size, anime }: ProgressBarProps) {
  return (
    <ProgressBarWrapper>
      <ProgressBarHeader>
        <div>LOADING...</div>
        <div>
          <AnimatedNumber start={0} finish={size} />
          /430kb
        </div>
      </ProgressBarHeader>
      <Line>
        <LineProgress anime={anime} />
      </Line>
    </ProgressBarWrapper>
  );
}
