export function RefreshIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M21 4.00011C20.7348 4.00011 20.4804 4.10546 20.2929 4.293C20.1054 4.48054 20 4.73489 20 5.00011V6.00411C19.0679 4.76256 17.86 3.75459 16.4717 3.05973C15.0833 2.36487 13.5525 2.00212 12 2.00011C9.73095 1.98958 7.52652 2.75523 5.75251 4.17C3.97851 5.58477 2.74158 7.56359 2.247 9.77811C2.18812 10.0368 2.23443 10.3083 2.37574 10.5329C2.51705 10.7575 2.74178 10.9167 3.0005 10.9756C3.25922 11.0345 3.53073 10.9882 3.75531 10.8469C3.97988 10.7056 4.13912 10.4808 4.198 10.2221C4.59343 8.45048 5.58286 6.86737 7.00204 5.73559C8.42123 4.60381 10.1848 3.99143 12 4.00011C14.885 4.00011 17.502 5.56511 18.914 8.00011H17C16.7348 8.00011 16.4804 8.10546 16.2929 8.293C16.1054 8.48054 16 8.73489 16 9.00011C16 9.26532 16.1054 9.51968 16.2929 9.70721C16.4804 9.89475 16.7348 10.0001 17 10.0001H21C21.2652 10.0001 21.5196 9.89475 21.7071 9.70721C21.8946 9.51968 22 9.26532 22 9.00011V5.00011C22 4.73489 21.8946 4.48054 21.7071 4.293C21.5196 4.10546 21.2652 4.00011 21 4.00011ZM3 20.0001C3.26522 20.0001 3.51957 19.8948 3.70711 19.7072C3.89464 19.5197 4 19.2653 4 19.0001V17.9751C4.92469 19.2261 6.13051 20.2421 7.52017 20.9413C8.90982 21.6405 10.4444 22.0032 12 22.0001C14.269 22.0106 16.4735 21.245 18.2475 19.8302C20.0215 18.4154 21.2584 16.4366 21.753 14.2221C21.8119 13.9634 21.7656 13.6919 21.6243 13.4673C21.483 13.2427 21.2582 13.0835 20.9995 13.0246C20.7408 12.9657 20.4693 13.012 20.2447 13.1533C20.0201 13.2947 19.8609 13.5194 19.802 13.7781C19.4066 15.5497 18.4171 17.1328 16.998 18.2646C15.5788 19.3964 13.8152 20.0088 12 20.0001C9.114 20.0001 6.522 18.4721 5.092 16.0001H7C7.26522 16.0001 7.51957 15.8947 7.70711 15.7072C7.89464 15.5197 8 15.2653 8 15.0001C8 14.7349 7.89464 14.4805 7.70711 14.293C7.51957 14.1055 7.26522 14.0001 7 14.0001H3C2.73478 14.0001 2.48043 14.1055 2.29289 14.293C2.10536 14.4805 2 14.7349 2 15.0001V19.0001C2 19.2653 2.10536 19.5197 2.29289 19.7072C2.48043 19.8948 2.73478 20.0001 3 20.0001Z"
        fill="#F0F0F0"
      />
    </svg>
  );
}
