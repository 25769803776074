import { AxiosResponse } from 'axios';
import { $api } from '..';
import { StartBruteforceDto } from '../../types';

const basePath = '/bruteforce';

export async function getBruteforce(): Promise<AxiosResponse<object>> {
  return $api.get(`${basePath}/`);
}

export async function startBruteforce(): Promise<AxiosResponse<StartBruteforceDto>> {
  return $api.get(`${basePath}/start`);
}

export async function nextBruteforce(symbols: string): Promise<AxiosResponse<object>> {
  return $api.get(`${basePath}/next`, { params: { symbols } });
}

export async function stoptBruteforce(): Promise<AxiosResponse<object>> {
  return $api.get(`${basePath}/stop`);
}
