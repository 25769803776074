import axios, { AxiosRequestHeaders } from 'axios';
import * as auth from './methods/auth';
import * as bruteforce from './methods/bruteforce';
import * as game from './methods/game';
import * as rewards from './methods/rewards';
import * as tasks from './methods/tasks';
import * as upgrades from './methods/upgrades';

const DOMEN = process.env.REACT_APP_API_DOMEN;
const API_URL = `https://${DOMEN}/v1`;

export const $api = axios.create({
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  if (config.headers) {
    const token = localStorage.getItem('token');

    config.headers = {
      Authorization:
        config.headers.Authorization === false ? false : token ? `Bearer ${token}` : '',
      'Content-Type': 'application/x-www-form-urlencoded',
    } as AxiosRequestHeaders;
  }
  return config;
});

export const Api = {
  // ~~ Auth
  loginTg: auth.loginTg,

  // ~~ Bruteforce
  getBruteforce: bruteforce.getBruteforce,
  startBruteforce: bruteforce.startBruteforce,
  nextBruteforce: bruteforce.nextBruteforce,
  stoptBruteforce: bruteforce.stoptBruteforce,

  // ~~ Game
  getGame: game.getGame,

  // ~~ Rewards
  getRewards: rewards.getRewards,

  // ~~ Tasks
  getTasks: tasks.getTasks,

  // ~~ Upgrades
  getUpgrades: upgrades.getUpgrades,
  purchaseUpgrade: upgrades.purchaseUpgrade,
};
