export function IconHacking() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M2.40465 14.2173L0.581216 19.1298C0.267153 19.9735 0.417153 20.9204 0.974966 21.6235L2.41403 23.4512C2.54528 23.6199 2.75153 23.7184 2.96715 23.7184H4.28903C4.2234 23.3903 4.2984 23.0481 4.49997 22.7809C4.34997 22.5793 4.26559 22.3309 4.26559 22.0778V14.109C4.26559 13.7982 4.38906 13.5001 4.60883 13.2804C4.82859 13.0606 5.12667 12.9371 5.43747 12.9371H6.00465C5.60934 12.5558 5.23978 12.1487 4.8984 11.7184L3.70309 12.5481C3.10931 12.9581 2.656 13.5409 2.40465 14.2173ZM18.5625 23.2501H5.43747C5.13278 23.2501 5.13278 23.7189 5.43747 23.7189H18.5625C18.8672 23.7189 18.8672 23.2501 18.5625 23.2501ZM21.5859 23.4517L23.025 21.624C23.2995 21.2773 23.48 20.8656 23.5489 20.4288C23.6179 19.9919 23.5731 19.5447 23.4187 19.1303L21.5953 14.2173C21.3439 13.5409 20.8906 12.9581 20.2968 12.5481L19.1015 11.7184C18.7593 12.1496 18.389 12.5574 17.9953 12.9371H18.5625C18.8733 12.9371 19.1713 13.0606 19.3911 13.2804C19.6109 13.5001 19.7343 13.7982 19.7343 14.109V22.0778C19.7343 22.3309 19.65 22.5793 19.5 22.7809C19.7015 23.0481 19.7765 23.3903 19.7109 23.7184H21.0328C21.2484 23.7189 21.4547 23.6204 21.5859 23.4517Z"
        fill="#F0F0F0"
      />
      <path
        d="M18.7968 14.1094C18.7968 13.9781 18.6937 13.875 18.5625 13.875H5.43745C5.3062 13.875 5.20308 13.9781 5.20308 14.1094V22.0781C5.20308 22.2094 5.3062 22.3125 5.43745 22.3125H18.5625C18.6937 22.3125 18.7968 22.2094 18.7968 22.0781V14.1094ZM13.1578 19.8H10.8421C10.3078 19.8 9.96558 19.2047 10.2328 18.7453L11.3906 16.7395C11.6578 16.2802 12.3421 16.2802 12.6093 16.7395L13.7671 18.7453C14.0343 19.2047 13.6875 19.8 13.1578 19.8ZM19.0265 9.99844C18.0259 9.28174 16.9207 8.72362 15.75 8.34375V8.71875C15.75 10.7625 14.0437 12.4687 12 12.4687C9.9562 12.4687 8.24995 10.7625 8.24995 8.71875V8.34375C7.22339 8.67656 6.25308 9.14531 5.35308 9.73594C5.22651 9.82031 5.09995 9.90937 4.97339 9.99891C5.08589 10.3266 5.24526 10.6317 5.4562 10.9078C6.02339 11.6625 6.68433 12.3469 7.42495 12.9375H16.575C17.3156 12.3469 17.9765 11.6625 18.5437 10.9078C18.7546 10.6312 18.914 10.3266 19.0265 9.99844Z"
        fill="#F0F0F0"
      />
      <path
        d="M4.7953 8.98129C6.432 7.89458 8.29334 7.19221 10.24 6.92676C12.1866 6.66131 14.168 6.83967 16.0359 7.44848C17.1375 7.80942 18.2062 8.32036 19.2047 8.98129C19.2094 8.59739 19.1484 8.21723 19.0219 7.85207C18.2394 5.60676 17.01 3.54334 15.4078 1.78645C13.6031 -0.191675 10.3969 -0.191675 8.59217 1.78645C6.98998 3.54334 5.76054 5.60676 4.97811 7.85207C4.85155 8.21723 4.79061 8.59692 4.7953 8.98129Z"
        fill="#F0F0F0"
      />
    </svg>
  );
}
