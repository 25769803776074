import { useState } from 'react';
import { Api } from '../../api';
import { UnknownFunction, UpgradeDto } from '../../types';
import { Button, Modal } from '../UI';
import styled from 'styled-components';
import { formatTimerTime, getBalance, getHourlyBalance, toast } from '../../utils';
import { IconCoinDarknet } from '../../icons';
import { eventSetGame, eventUpdateUpgrade } from '../../stores';

const Profit = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #32c5f3;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.36px;
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  font-weight: 700;
  font-size: 24px;
`;

const NotCoins = styled.div`
  text-align: center;
  color: #d23c3c;
  font-size: 20px;
`;

interface UpgradeModalProps {
  upgrade: UpgradeDto;
  secondsLeft: number;
  isNotCoins: boolean;
  onClose: UnknownFunction;
}

export function UpgradeModal({ upgrade, secondsLeft, isNotCoins, onClose }: UpgradeModalProps) {
  const [isPending, setIsPending] = useState(false);

  const purchaseUpgrade = async () => {
    setIsPending(true);
    await Api.purchaseUpgrade(upgrade.id)
      .then((res) => {
        toast.success('Upgrade is yours!');
        eventUpdateUpgrade(res.data);
        updateGame();
        onClose();
      })
      .catch(() => toast.error('Error!'));
    setIsPending(false);
  };

  const updateGame = async () => {
    await Api.getGame().then((res) => eventSetGame(res.data));
  };

  return (
    <Modal
      title={upgrade.title}
      descriptionTitle={upgrade.long_description}
      onClose={onClose}
      bottomSection={
        <>
          {secondsLeft ? (
            <Button bgColor={'#000'}>{formatTimerTime(secondsLeft)}</Button>
          ) : (
            <>
              {isNotCoins ? (
                <Button isPending={isPending} bgColor={'#000'}>
                  <NotCoins>NOT ENOUGH COINS</NotCoins>
                </Button>
              ) : (
                <Button onClick={purchaseUpgrade} isPending={isPending} bgColor={'#000'}>
                  UPGRADE
                </Button>
              )}
            </>
          )}
        </>
      }>
      <Profit>
        <p>Profit per hour:</p>
        <span>+{getHourlyBalance(upgrade.next_hourly_income)}</span>
      </Profit>

      <Price>
        <IconCoinDarknet />
        <div>{getBalance(upgrade.next_cost)}</div>
      </Price>
    </Modal>
  );
}
