import styled from 'styled-components';
import { UnknownFunction } from '../../types';
import { triggerHaptic } from '../../utils';
import { ReactNode } from 'react';

const ModalButtonWrapper = styled.button`
  position: relative;
  width: fit-content;
  height: 80px;
  margin: 0 auto;
  padding: 6px 16px;
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 30px;
`;

const EffectTop = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;

  width: 70px;
  height: 50px;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 3px,
    #252525 3px,
    #252525 6px
  );

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background-color: #000;
  }
`;

const EffectBottom = styled.div`
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;

  width: 48px;
  height: 25px;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 3px,
    #252525 3px,
    #252525 6px
  );

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    background-color: #000;
  }
`;

const ButtonLabel = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  height: 16px;
  margin-top: -5px;
  padding: 0 8px 0 22px;

  background-color: #fff;
  color: #000;
  font-size: 10px;
  font-weight: 800;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 16px;
    height: 16px;
    margin-top: -2px;
    margin-left: -5px;
    background-color: #000;
    border: 1px solid #32c5f3;
    transform: rotate(-45deg);
  }
`;

interface OfflineProfitModalProps {
  children: ReactNode;
  onClick?: UnknownFunction;
}

export function ModalButton({ children, onClick }: OfflineProfitModalProps) {
  const handleClick = () => {
    triggerHaptic();
    if (onClick) onClick();
  };

  return (
    <ModalButtonWrapper onClick={handleClick}>
      <EffectTop />
      <EffectBottom />
      <ButtonLabel>PROCESSING</ButtonLabel>
      {children}
    </ModalButtonWrapper>
  );
}
