import { Bar, TeamMembers, TeamRewards } from '../components';
import { useState } from 'react';

enum TeamPageBar {
  Rewards = 'Rewards',
  Members = 'Members',
}

export function TeamPage() {
  const [cat, setCat] = useState(TeamPageBar.Rewards);

  return (
    <>
      <Bar list={TeamPageBar} value={cat} setValue={setCat} />
      {cat === TeamPageBar.Rewards && <TeamRewards />}
      {cat === TeamPageBar.Members && <TeamMembers />}
    </>
  );
}
