import { useMemo } from 'react';

interface IconCoinDarknetProps {
  color?: 'white';
}

export function IconCoinDarknet({ color }: IconCoinDarknetProps) {
  const currentColor = useMemo(() => {
    if (color === 'white') return '#F0F0F0';
    return '#32C5F3';
  }, [color]);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M24.141 14.9019C22.5377 21.334 16.0211 25.2438 9.59828 23.6405C3.16609 22.0372 -0.743861 15.5208 0.8595 9.09808C2.46286 2.66603 8.97007 -1.24383 15.4023 0.359492C21.8251 1.95344 25.7444 8.46987 24.141 14.9019Z"
        fill={currentColor}
      />
      <path
        d="M18.2136 10.5047C18.4479 8.91093 17.2386 8.04843 15.5698 7.47655L16.1136 5.31093L14.8011 4.9828L14.2761 7.09218C13.9292 7.0078 13.5729 6.92343 13.2167 6.84843L13.7417 4.72968L12.4292 4.40155L11.8948 6.5578C11.6042 6.49217 11.3229 6.42655 11.0511 6.36093V6.35155L9.23232 5.90155L8.88545 7.3078C8.88545 7.3078 9.86045 7.5328 9.8417 7.54217C10.3761 7.67342 10.4698 8.02968 10.4511 8.31093L9.83232 10.7766C9.86982 10.7859 9.9167 10.7953 9.97295 10.8234C9.92607 10.8141 9.8792 10.8047 9.83232 10.7859L8.96982 14.2359C8.9042 14.3953 8.73545 14.639 8.36982 14.5453C8.3792 14.564 7.41357 14.3109 7.41357 14.3109L6.75732 15.8203L8.47295 16.2516C8.7917 16.3359 9.10107 16.4109 9.41045 16.4953L8.8667 18.6797L10.1792 19.0078L10.7229 16.8422C11.0792 16.9359 11.4354 17.0297 11.7729 17.1141L11.2386 19.2703L12.5511 19.5984L13.0948 17.4141C15.3448 17.8359 17.0323 17.6672 17.7354 15.6328C18.3073 14.0016 17.7073 13.0547 16.5261 12.4359C17.3979 12.239 18.0448 11.6672 18.2136 10.5047ZM15.2042 14.7234C14.8011 16.3547 12.0448 15.4734 11.1542 15.2484L11.8761 12.3516C12.7667 12.5766 15.6354 13.0172 15.2042 14.7234ZM15.6167 10.4766C15.2417 11.9672 12.9542 11.2078 12.2136 11.0203L12.8698 8.3953C13.6104 8.5828 16.0011 8.92968 15.6167 10.4766Z"
        fill="black"
      />
      <path
        d="M18.5127 15.7875C16.3002 15.7875 14.5127 17.575 14.5127 19.7875C14.5127 22 16.3002 23.7875 18.5127 23.7875C20.7252 23.7875 22.5127 22 22.5127 19.7875C22.5127 17.575 20.7127 15.7875 18.5127 15.7875ZM18.5127 17.2875C18.9752 17.2875 19.4127 17.4125 19.7752 17.6375L16.3627 21.05C16.1377 20.675 16.0127 20.25 16.0127 19.7875C16.0127 18.4125 17.1252 17.2875 18.5127 17.2875ZM18.5127 22.2875C18.0502 22.2875 17.6127 22.1625 17.2502 21.9375L20.6752 18.5125C20.9002 18.8875 21.0252 19.3125 21.0252 19.775C21.0127 21.1625 19.8877 22.2875 18.5127 22.2875Z"
        fill="#D23C3C"
      />
    </svg>
  );
}
