import styled from 'styled-components';
import { IconCoinDarknet, IconPython, LockIcon } from '../icons';
import { UpgradeDto } from '../types';
import { getHourlyBalance, getRemainingSeconds, triggerHaptic } from '../utils';
import { useEffect, useMemo, useState } from 'react';
import { UpgradeModal } from './modals';
import { Timer } from './timer';
import { useUnit } from 'effector-react';
import { $gameStore } from '../stores';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 5px 0;
`;

const BG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px dashed #32c5f3;
`;

const Content = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 64px 1fr 48px;
`;

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 4px 8px;
  background-color: #f0f0f0;
  font-weight: 700;
  font-size: 12px;
  color: #000;

  label {
    font-weight: 800;
  }
`;

interface LogoProps {
  isLook?: boolean;
}

const Logo = styled.div<LogoProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  min-width: 64px;
  height: 64px;
  margin-left: -6px;
  border: 2px solid #32c5f3;
  background-color: #000;
  background-color: #d23c3c;
  background-color: ${({ isLook }) => {
    if (isLook) return '#d23c3c';
    return '#000';
  }};
`;

const Level = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border: 2px solid #32c5f3;
  margin: 0 auto;

  label {
    font-size: 10px;
    font-weight: 700;
  }

  span {
    font-size: 14px;
    font-weight: 700;
  }
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 6px;

  svg {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  span {
    font-size: 12px;
    font-weight: 600;
  }
`;

const Open = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 20px;
  border-top: 1px dashed #32c5f3;
  border-left: 1px dashed #32c5f3;
  font-size: 12px;
  font-weight: 700;
`;

const NotCoins = styled.div`
  margin-left: 16px;
  font-size: 12px;
  font-weight: 600;
  color: #d23c3c;
  letter-spacing: -0.36px;
`;

interface SkillProps {
  upgrade: UpgradeDto;
}

export function Skill({ upgrade }: SkillProps) {
  const game = useUnit($gameStore);

  const [isModal, setIsModal] = useState(false);

  const isNotCoins = useMemo(() => {
    if (game && game?.current_balance < upgrade.next_cost) return true;
    return false;
  }, [game?.current_balance, upgrade.next_cost]);
  const isLock = false;

  const now = new Date();
  const totalSeconds = 10;
  const defaultSecondsLeft = getRemainingSeconds(totalSeconds, now);

  const [secondsLeft, setSecondsLeft] = useState(defaultSecondsLeft);

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsLeft((prevSeconds) => {
        if (prevSeconds > 0) return prevSeconds - 1;
        clearInterval(interval);
        return 0;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Wrapper
        onClick={() => {
          triggerHaptic();
          setIsModal(true);
        }}>
        <BG />
        <Content>
          <Logo isLook={isLock}>
            {isLock ? <LockIcon /> : <IconPython />}
            <Timer totalSeconds={totalSeconds} secondsLeft={secondsLeft} />
          </Logo>
          <div>
            <ContentHeader>
              <label>{upgrade.title}</label>
              <div>(+{getHourlyBalance(upgrade.next_hourly_income)}/h)</div>
            </ContentHeader>
            <Price>
              <IconCoinDarknet color={'white'} />
              <span>{getHourlyBalance(upgrade.current_hourly_income)}</span>
              {isNotCoins && <NotCoins>Not enough coins</NotCoins>}
            </Price>
          </div>
          <Level>
            <label>lvl.</label>
            <span>{upgrade.lvl}</span>
          </Level>
        </Content>
        <Open>open</Open>
      </Wrapper>

      {isModal && (
        <UpgradeModal
          upgrade={upgrade}
          secondsLeft={secondsLeft}
          isNotCoins={isNotCoins}
          onClose={() => setIsModal(false)}
        />
      )}
    </>
  );
}
