import { ReactNode } from 'react';
import { UnknownFunction } from '../../types';
import styled from 'styled-components';
import { triggerHaptic } from '../../utils';
import { Spinner } from './spinner';

interface EffectProps {
  colorEffect?: 'blue';
  bgColor?: string;
}

const Wrapper = styled.button<EffectProps>`
  position: relative;
  width: fit-content;
  min-width: 80px;
  height: 60px;
  margin: 0 auto;
  padding: 6px 16px;
  border: none;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#111')};
  color: #fff;
  font-size: 24px;
`;

const EffectTop = styled.div<EffectProps>`
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;

  width: 70px;
  height: 50px;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 3px,
    ${({ colorEffect }) => {
        if (colorEffect === 'blue') return '#32C5F3';
        return '#252525';
      }}
      3px,
    ${({ colorEffect }) => {
        if (colorEffect === 'blue') return '#32C5F3';
        return '#252525';
      }}
      6px
  );

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background-color: ${({ bgColor }) => (bgColor ? bgColor : '#111')};
  }
`;

const EffectBottom = styled.div<EffectProps>`
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;

  width: 48px;
  height: 25px;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 3px,
    ${({ colorEffect }) => {
        if (colorEffect === 'blue') return '#32C5F3';
        return '#252525';
      }}
      3px,
    ${({ colorEffect }) => {
        if (colorEffect === 'blue') return '#32C5F3';
        return '#252525';
      }}
      6px
  );
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 3px,
    ${({ colorEffect }) => {
        if (colorEffect === 'blue') return '#32C5F3';
        return '#252525';
      }}
      3px,
    ${({ colorEffect }) => {
        if (colorEffect === 'blue') return '#32C5F3';
        return '#252525';
      }}
      6px
  );

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    background-color: ${({ bgColor }) => (bgColor ? bgColor : '#111')};
  }
`;

const Content = styled.div`
  z-index: 50;
  position: relative;
  min-width: 100px;
`;

interface ButtonProps extends EffectProps {
  children: ReactNode;
  disabled?: boolean;
  isPending?: boolean;
  onClick?: UnknownFunction;
}

export function Button({
  children,
  disabled,
  isPending,
  colorEffect,
  bgColor,
  onClick,
}: ButtonProps) {
  return (
    <Wrapper
      disabled={disabled || isPending}
      bgColor={bgColor}
      onClick={() => {
        triggerHaptic();
        if (onClick) onClick();
      }}>
      <EffectTop colorEffect={colorEffect} bgColor={bgColor} />
      <EffectBottom colorEffect={colorEffect} bgColor={bgColor} />
      <Content>{isPending ? <Spinner /> : children}</Content>
    </Wrapper>
  );
}
