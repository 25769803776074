import styled from 'styled-components';
import { IconMackbook } from '../../icons/icon-mackbook';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  margin: 0 auto;

  svg {
    max-width: 100%;
  }
`;

const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: calc(100% - 68px);
  height: 164px;
  margin-top: 24px;
  padding: 2px 20px 6px;
`;

const InputLabel = styled.div`
  margin-bottom: 6px;
  font-size: 9px;
  color: #909090;
`;

const Input = styled.div`
  padding: 2px 6px;
  border: 1px solid #252525;
  word-wrap: break-word;
  font-size: 14px;

  .true {
    color: #32c5f3;
  }

  .current {
    color: #d23c3c;
  }

  .pending {
    color: #909090;
  }
`;

const ButtonsSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

interface LetterProps {
  isActive?: boolean;
  isDone?: boolean;
}
const Letter = styled.div<LetterProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  aspect-ratio: 1;
  border: 2px solid
    ${({ isActive, isDone }) => {
      if (isActive) return '#D23C3C';
      if (isDone) return '#32C5F3';
      return '#909090';
    }};
  font-weight: 600;
  font-size: 20px;
`;

const Triangle = styled.div<LetterProps>`
  position: absolute;
  right: -2px;
  bottom: -2px;
  border: 6px solid transparent;
  border-right: 6px solid
    ${({ isActive, isDone }) => {
      if (isActive) return '#D23C3C';
      if (isDone) return '#32C5F3';
      return '#909090';
    }};
  border-bottom: 6px solid
    ${({ isActive, isDone }) => {
      if (isActive) return '#D23C3C';
      if (isDone) return '#32C5F3';
      return '#909090';
    }};

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: -6px;
    bottom: -6px;
    border: 4px solid transparent;
    border-right: 4px solid #000;
    border-bottom: 4px solid #000;
  }
`;

const Passed = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #83bba2;
  font-size: 30px;
`;

const Expired = styled(Passed)`
  color: #d23c3c;
`;

interface MiniGameMacbookProps {
  code: string;
  answer: string[];
  word: string;
  currentSymbol: string;
  currentIndex: number;
  isPassed: boolean;
  isExpired: boolean;
}

export function MiniGameMacbook({
  code,
  answer,
  word,
  currentSymbol,
  currentIndex,
  isPassed,
  isExpired,
}: MiniGameMacbookProps) {
  return (
    <Wrapper>
      <IconMackbook />
      <Content>
        <div>
          <InputLabel>Password</InputLabel>
          <Input>{isPassed ? word : '********'}</Input>
        </div>
        {isPassed && (
          <Passed>
            ACCESS
            <br />
            GRANTED
          </Passed>
        )}
        {isExpired && !isPassed && (
          <Expired>
            ACCESS
            <br />
            DENIED
          </Expired>
        )}
        {!isPassed && !isExpired && (
          <>
            <div>
              <InputLabel>MD5</InputLabel>
              <Input>
                {code}
                {answer.map((word, i) => (
                  <span className={currentIndex === i ? 'current' : word ? 'true' : ''} key={i}>
                    {currentIndex === i ? currentSymbol : word ? word : '*'}
                  </span>
                ))}
              </Input>
            </div>
            <ButtonsSection>
              {answer.map((word, i) => (
                <Letter key={i} isActive={currentIndex === i} isDone={!!word}>
                  {currentIndex === i ? currentSymbol : word}
                  <Triangle isActive={currentIndex === i} isDone={!!word} />
                </Letter>
              ))}
            </ButtonsSection>
          </>
        )}
      </Content>
    </Wrapper>
  );
}
