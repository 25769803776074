import styled from 'styled-components';
import { UnknownFunction } from '../types';
import { Container } from './layout';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Toggle } from './UI';

const Wrapper = styled.div`
  position: fixed;
  z-index: 10000;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 16px 0;
  background-color: #000;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;

  h3 {
    font-size: 20px;
    font-weight: 700;
  }
`;

const Back = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  svg {
    width: 24px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

interface SettingsProps {
  onClose: UnknownFunction;
}

export function Settings({ onClose }: SettingsProps) {
  return (
    <Wrapper>
      <Container>
        <Heading>
          <Back onClick={onClose}>
            <ChevronLeftIcon />
          </Back>
          <h3>Settings</h3>
          <Back />
        </Heading>

        <List>
          <Toggle label={'Vibration'} />
        </List>
      </Container>
    </Wrapper>
  );
}
