import styled from 'styled-components';
import { IconCoinDarknet, IconPython } from '../icons';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 5px 0;
`;

const BG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px dashed #32c5f3;
`;

const Content = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 64px 1fr;
  padding-right: 5px;
`;

const ContentHeader = styled.div`
  padding: 4px 8px;
  background-color: #f0f0f0;
  font-weight: 700;
  font-size: 12px;
  color: #000;

  label {
    font-weight: 800;
  }

  p {
    font-size: 10px;
    font-weight: 600;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  min-width: 64px;
  height: 64px;
  margin-left: -6px;
  border: 2px solid #32c5f3;
  background-color: #000;
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 6px;

  svg {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }

  span {
    font-size: 12px;
    font-weight: 600;
  }
`;

const Open = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 20px;
  border-top: 1px dashed #32c5f3;
  border-left: 1px dashed #32c5f3;
  font-size: 12px;
  font-weight: 700;
`;

export function Reward() {
  return (
    <Wrapper>
      <BG />
      <Content>
        <Logo>
          <IconPython />
        </Logo>
        <div>
          <ContentHeader>
            <label>Python middle</label>
            <p>Start your ethical hacker way!</p>
          </ContentHeader>
          <Price>
            <IconCoinDarknet color={'white'} />
            <span>0.003</span>
          </Price>
        </div>
      </Content>
      <Open>open</Open>
    </Wrapper>
  );
}
