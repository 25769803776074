import { useEffect, useState } from 'react';
import { Bar, Container, ListItems, Scroll, Skill } from '../components';
import { $upgradesStore, eventSetUpgrades } from '../stores';
import { useUnit } from 'effector-react';
import { Api } from '../api';

enum UpgradeBar {
  Skills = 'Skills',
  Hardware = 'Hardware',
  Software = 'Software',
  More = 'More',
}

export function UpgradePage() {
  const [cat, setCat] = useState(UpgradeBar.Skills);
  const upgrades = useUnit($upgradesStore);

  return (
    <>
      <Bar list={UpgradeBar} value={cat} setValue={setCat} />
      <Scroll>
        <Container>
          <ListItems>
            {upgrades.map((upgrade) => (
              <Skill key={upgrade.id} upgrade={upgrade} />
            ))}
          </ListItems>
        </Container>
      </Scroll>
    </>
  );
}
