import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const SpinnerStyle = styled.div`
  border: 5px solid rgba(0, 0, 0, 0.6);
  border-top: 5px solid #fff;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export function Spinner() {
  return (
    <SpinnerWrapper>
      <SpinnerStyle />
    </SpinnerWrapper>
  );
}

const SpinnerPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export function SpinnerPage() {
  return (
    <SpinnerPageWrapper>
      <Spinner />
    </SpinnerPageWrapper>
  );
}
