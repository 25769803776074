import { useEffect, useState } from 'react';
import { Container, Reward, Bar, ListItems, Scroll } from '../components';
import { RewardsBar } from '../types';
import { Api } from '../api';

export function RewardPage() {
  const [cat, setCat] = useState(RewardsBar.Courses);

  const getRewards = async () => {
    await Api.getRewards();
  };

  useEffect(() => {
    getRewards();
  }, []);

  return (
    <>
      <Bar list={RewardsBar} value={cat} setValue={setCat} />

      <Scroll>
        <Container>
          <ListItems>
            <Reward />
            <Reward />
            <Reward />
            <Reward />
            <Reward />
            <Reward />
            <Reward />
            <Reward />
          </ListItems>
        </Container>
      </Scroll>
    </>
  );
}
