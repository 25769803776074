import styled from 'styled-components';
import { StartBruteforceDto, UnknownFunction } from '../../types';
import { MiniGameKeyboard } from './mini-game-keyboard';
import { MiniGameHeader } from './mini-game-header';
import { MiniGameMacbook } from './mini-game-macbook';
import { Api } from '../../api';
import { useEffect, useState } from 'react';
import md5 from 'crypto-js/md5';
import { SpinnerPage } from '../UI';
import { toast } from '../../utils';

const MiniGameWrapper = styled.div`
  z-index: 100;
  position: fixed;
  overflow: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 16px;
  background-color: #000;
`;

interface MiniGameProps {
  onClose: UnknownFunction;
}

export function MiniGame({ onClose }: MiniGameProps) {
  const [bruteforce, setBruteforce] = useState<StartBruteforceDto | null>();
  const [code, setCode] = useState<string | null>(null);
  const [answerCode, setAnswerCode] = useState<string | null>(null);
  const [isPassed, setIsPassed] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const [answer, setAnswer] = useState<string[]>(['', '', '', '']);
  const [currentSymbol, setCurrentSymbol] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [wrongSymbols, setWrongSymbols] = useState<string[]>([]);

  const startGame = async () => {
    setBruteforce(null);
    setIsPassed(false);
    setIsExpired(false);
    setCurrentIndex(0);
    setAnswer(['', '', '', '']);

    await Api.startBruteforce()
      .then((res) => {
        const codeMD5 = md5(res.data.word).toString();
        if (codeMD5.length > 4) {
          setAnswerCode(codeMD5.slice(-4));
          setCode(codeMD5.slice(0, -4));
        }

        setBruteforce(res.data);
        setCurrentSymbol(res.data.variants[currentIndex][0]);
      })
      .catch(() => {
        toast.error('Error!');
        onClose();
      });
  };

  const checkGame = async (answerCode: string) => {
    await Api.nextBruteforce(answerCode)
      .then(() => setIsPassed(true))
      .catch(() => setIsExpired(true));
  };

  useEffect(() => {
    startGame();
  }, []);

  return (
    <MiniGameWrapper>
      {bruteforce && code && answerCode ? (
        <>
          {process.env.REACT_APP_MODE === 'develop' && answerCode}
          <MiniGameHeader
            timeLeft={bruteforce.time_left}
            startedAt={bruteforce.started_at}
            isPassed={isPassed}
            onClose={onClose}
            setIsExpired={setIsExpired}
          />
          <MiniGameMacbook
            code={code}
            answer={answer}
            word={bruteforce.word}
            currentSymbol={currentSymbol}
            currentIndex={currentIndex}
            isPassed={isPassed}
            isExpired={isExpired}
          />
          <MiniGameKeyboard
            wrongSymbols={wrongSymbols}
            isPassed={isPassed}
            isExpired={isExpired}
            newGame={startGame}
            letters={bruteforce.variants[currentIndex]}
            setCurrentSymbol={(symbol) => setCurrentSymbol(symbol)}
            onClose={onClose}
            onEnter={(symbol) => {
              if (!isExpired && !isPassed) {
                if (symbol === answerCode[currentIndex]) {
                  if (currentIndex === 3) {
                    checkGame(answerCode);
                  } else {
                    setWrongSymbols([]);
                    setAnswer((prev) => {
                      const answer = [...prev];
                      answer[currentIndex] = symbol;
                      return answer;
                    });
                    setCurrentIndex((prev) => prev + 1);
                  }
                } else {
                  console.log('Не правильно');
                  setWrongSymbols((prev) => [...prev, symbol]);
                }
              }
            }}
          />
        </>
      ) : (
        <SpinnerPage />
      )}
    </MiniGameWrapper>
  );
}
