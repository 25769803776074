import { ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 163px;
  height: 56px;
`;

const IconSection = styled.div`
  position: absolute;
  top: 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 34px;
  height: 34px;
  border: 2px solid var(--Color-Blue, #32c5f3);
  transform: rotate(-45deg);
  background-color: #000;

  & > * {
    transform: rotate(45deg);
  }
`;

const Label = styled.label`
  position: absolute;
  top: 8px;
  left: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 116px;
  height: 18px;
  padding-left: 10px;

  background-color: #f0f0f0;
  color: #000;
  font-weight: 800;
  font-size: 12px;

  &::after {
    content: '';
    position: absolute; /* Абсолютное позиционирование */
    top: 0;
    right: -18px;
    border: 9px solid transparent; /* Прозрачные границы */
    border-left: 9px solid #f0f0f0; /* Добавляем треугольник */
  }
`;

const BG = styled.div`
  position: absolute;
  top: 0;
  left: 20px;

  width: 134px;
  height: 48px;
  padding: 4px;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 2px,
    #252525 2px,
    #252525 4px
  );

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    background-color: #000;
  }
`;

const Rectangle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  width: 57px;
  height: 20px;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 2px,
    #252525 2px,
    #252525 4px
  );
`;

const Value = styled.div`
  position: absolute;
  top: 24px;
  left: 44px;
  font-weight: 800;
  color: #f0f0f0;
`;

interface InfoCardProps {
  label: string;
  icon: ReactNode;
  value: string | number;
}

export function InfoCard({ label, icon, value }: InfoCardProps) {
  return (
    <Wrapper>
      <BG />
      <Rectangle />
      <Label>{label}</Label>
      <IconSection>{icon}</IconSection>
      <Value>{value}</Value>
    </Wrapper>
  );
}
