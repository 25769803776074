import styled from 'styled-components';

const Wrapper = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid #32c5f3;
  border-radius: 100%;
`;

export function Avatar() {
  return (
    <Wrapper>
      <img src={'./assets/img/avatar.png'} alt={''} />
    </Wrapper>
  );
}
