import styled from 'styled-components';
import { IconCoinDarknet, IconInfo } from '../icons';

const Wrapper = styled.div`
  position: relative;
  width: fit-content;
  min-width: 140px;
  padding: 4px;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 3px,
    #252525 3px,
    #252525 6px
  );
`;

const Content = styled.div`
  width: 100%;
  height: 48px;
  background-color: #000;
`;

const Label = styled.div`
  white-space: nowrap;
  width: calc(100% - 4px);
  margin: -8px 2px;
  padding: 0 6px;
  background-color: #f0f0f0;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: -0.36px;
  color: #000;
`;

const Info = styled.div`
  position: absolute;
  right: -10px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 3px,
    #252525 3px,
    #252525 6px
  );
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 100%;

  span {
    display: block;
    letter-spacing: -0.42px;
    font-size: 14px;
    font-weight: 600;
  }
`;

interface MemberRewardProps {
  label: string;
}

export function MemberReward({ label }: MemberRewardProps) {
  return (
    <Wrapper>
      <Content>
        <Label>{label}</Label>
        <Count>
          <IconCoinDarknet color={'white'} />
          <span>+0.0015</span>
        </Count>
      </Content>
      <Info>
        <IconInfo />
      </Info>
    </Wrapper>
  );
}
