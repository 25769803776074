export function IconPython() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path
        d="M17.8706 2.36262C9.88313 2.36262 10.3883 5.81637 10.3883 5.81637V9.40512H17.9843V10.5301H7.36312C7.36312 10.5301 2.25 9.90012 2.25 18.0057C2.25 26.1114 6.76463 25.7817 6.76463 25.7817H9.37125V22.0006C9.37125 22.0006 9.225 17.486 13.7587 17.486H21.366C21.366 17.486 25.6095 17.5535 25.6095 13.3775V6.52512C25.6095 6.52512 26.253 2.34912 17.9122 2.34912L17.8706 2.36262ZM13.6721 4.76675C13.9422 4.75993 14.2082 4.83335 14.4365 4.97772C14.6648 5.12209 14.8452 5.33093 14.9548 5.57781C15.0644 5.8247 15.0984 6.09855 15.0524 6.36473C15.0063 6.63092 14.8824 6.87747 14.6963 7.07322C14.5101 7.26897 14.2701 7.40513 14.0066 7.46446C13.743 7.52379 13.4678 7.50364 13.2157 7.40656C12.9636 7.30948 12.746 7.13982 12.5904 6.91904C12.4347 6.69827 12.348 6.43629 12.3413 6.16625V6.14375C12.3413 5.96439 12.3766 5.7868 12.4452 5.6211C12.5138 5.4554 12.6144 5.30484 12.7413 5.17802C12.8681 5.05119 13.0187 4.95059 13.1844 4.88196C13.3501 4.81332 13.5276 4.778 13.707 4.778L13.6721 4.76675Z"
        fill="#F0F0F0"
      />
      <path
        d="M18.0955 33.6489C26.083 33.6489 25.5779 30.1839 25.5779 30.1839V26.6064H17.983V25.4814H28.6367C28.6367 25.4814 33.7499 26.0529 33.7499 17.9945C33.7499 9.93612 29.2353 10.2185 29.2353 10.2185H26.5949V13.9501C26.5949 13.9501 26.7411 18.4647 22.2074 18.4647H14.5968C14.5968 18.4647 10.3499 18.4006 10.3499 22.5766V29.4751C10.3499 29.4751 9.70638 33.6511 18.0471 33.6511H18.0921L18.0955 33.6489ZM22.294 31.2335C22.024 31.2403 21.758 31.1669 21.5296 31.0225C21.3013 30.8781 21.1209 30.6693 21.0113 30.4224C20.9017 30.1755 20.8678 29.9017 20.9138 29.6355C20.9598 29.3693 21.0837 29.1228 21.2699 28.927C21.456 28.7313 21.696 28.5951 21.9596 28.5358C22.2231 28.4764 22.4983 28.4966 22.7504 28.5937C23.0025 28.6908 23.2201 28.8604 23.3758 29.0812C23.5314 29.302 23.6181 29.5639 23.6249 29.834V29.8677C23.6249 30.23 23.481 30.5773 23.2249 30.8335C22.9687 31.0896 22.6213 31.2335 22.2591 31.2335H22.294Z"
        fill="#F0F0F0"
      />
    </svg>
  );
}
