import styled from 'styled-components';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { IconCoinDarknet, IconReward } from '../icons';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 90px 40px;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  width: 100%;
  padding: 4px 0;
  border-bottom: 1px solid #252525;

  &:last-child {
    border-bottom: navy;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;

  svg {
    width: 24px;
  }
`;

const Description = styled.div`
  label {
    font-size: 14px;
    font-weight: 800;
    line-height: 1.57;
    letter-spacing: -0.42px;
  }

  p {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: -0.36px;
  }
`;

const Reward = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.42px;

  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
`;

export function Task() {
  return (
    <Wrapper>
      <Icon>
        <IconReward />
      </Icon>
      <Description>
        <label>Daily reward</label>
        {/* <p>Cyber yozh official channel</p> */}
      </Description>
      <Reward>
        <IconCoinDarknet />
        +0.03671
      </Reward>
      <Icon>
        <ChevronRightIcon />
      </Icon>
    </Wrapper>
  );
}
