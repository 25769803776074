import { Outlet } from 'react-router-dom';
import { $gameStore } from '../../stores';
import { useUnit } from 'effector-react';
import { LoadingPage } from '../../pages';
import styled from 'styled-components';
import { Header } from './header';
import { Nav } from './nav';
import { ModalRoot } from '../UI';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 460px;
  margin: 0 auto;
  padding-bottom: 90px;
  height: var(--tg-viewport-height);
`;

const ToastContainer = styled.div`
  z-index: 9999999999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 12px;
`;

export function Layout() {
  const game = useUnit($gameStore);

  return (
    <Wrapper>
      {game && <Header />}
      {game && <Outlet />}
      {game && <Nav />}
      <LoadingPage />
      <ToastContainer id="toasts-container" />
      <ModalRoot />
    </Wrapper>
  );
}
