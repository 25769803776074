import styled from 'styled-components';
import { OfflineProfitModal, ProgressBar } from '../components';
import { retrieveLaunchParams, postEvent } from '@telegram-apps/sdk-react';
import { Api } from '../api';
import { useEffect, useState } from 'react';
import { $gameStore, eventSetGame, eventSetUpgrades } from '../stores';
import { useQuery } from 'react-query';
import { useUnit } from 'effector-react';

postEvent('web_app_set_header_color', { color: '#000000' });
const { initDataRaw } = retrieveLaunchParams();

const LoadingPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 34px;
`;

const TheGame = styled.div`
  font-size: 20px;
  color: #32c5f3;

  span {
    color: #fff;
  }
`;

const LoadingPageHeader = styled.div`
  padding: 0 34px;
`;

const ImageSection = styled.div`
  width: 100%;
  aspect-ratio: 1;
`;

declare global {
  interface Window {
    Telegram: any;
  }
}

export function LoadingPage() {
  const game = useUnit($gameStore);

  const [size, setSize] = useState(129);
  const [anime, setAnime] = useState(1);

  const [isLogin, setIsLogin] = useState(false);
  const [isProfitModal, setIsProfitModal] = useState(true);

  const { data } = useQuery('game', Api.getGame, { enabled: isLogin });

  const login = async () => {
    if (initDataRaw)
      await Api.loginTg(initDataRaw)
        .then((res) => {
          localStorage.setItem('token', res.data.access_token);
          setTimeout(() => {
            getUpdates();
            setSize(301);
            setAnime(2);
          }, 2000);
        })
        .catch(console.warn);
  };

  const getUpdates = async () => {
    await Api.getUpgrades().then((res) => {
      eventSetUpgrades(res.data.items);
      setIsLogin(true);
    });
  };

  useEffect(() => {
    login();
  }, []);

  useEffect(() => {
    if (data?.data) {
      if (game) {
        eventSetGame(data.data);
      } else {
        setTimeout(() => {
          setSize(430);
          setAnime(3);
        }, 2000);
        setTimeout(() => {
          eventSetGame(data.data);
        }, 3000);
      }
    }
  }, [data, game]);

  if (game)
    return <> {isProfitModal && <OfflineProfitModal onClose={() => setIsProfitModal(false)} />}</>;

  return (
    <LoadingPageWrapper>
      <LoadingPageHeader>
        <img src={'./assets/img/logo.png'} alt={''} />
        <TheGame>
          the@game<span>:</span>~<span>$</span>
        </TheGame>
      </LoadingPageHeader>
      <ProgressBar size={size} anime={anime} />
      <ImageSection>
        <img src={'./assets/img/bg.jpg'} alt={''} />
      </ImageSection>
    </LoadingPageWrapper>
  );
}
