export function IconLogo() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Tab Bar Icons">
        <path
          id="Vector"
          d="M32.3451 19.2663C32.3814 18.849 32.4 18.4267 32.4 18C32.4 10.0471 25.9529 3.59998 18 3.59998C10.0471 3.59998 3.59998 10.0471 3.59998 18C3.59998 25.9529 10.0471 32.4 18 32.4C19.2382 32.4 20.4399 32.2437 21.5865 31.9498C19.9231 31.5573 17.6108 30.9939 16.6553 30.6782C15.6127 30.3336 14.0394 29.3881 13.2013 28.5305C13.0401 28.4141 12.8843 28.2932 12.7427 28.1634C12.3072 27.7618 11.0288 26.0615 12.1441 25.7632C12.3191 25.717 12.4934 25.778 12.6762 25.8421C12.8685 25.9095 13.0702 25.9801 13.292 25.9327C13.6585 25.8543 14.0223 25.6664 14.3371 25.4615C14.9476 25.0643 15.4359 24.4931 15.818 23.8637C16.1539 23.31 16.7784 22.2541 16.7351 21.5533C16.7351 21.5533 16.6291 19.8488 15.0856 18.2608C15.726 18.2295 18.2192 18.9678 18.9476 19.4002C19.3116 17.3475 17.53 14.7127 16.958 14.101C18.0122 14.1363 21.0838 15.9416 22.4357 18.1035C23.1092 17.2396 23.4124 13.5848 23.1323 11.7005C24.8214 12.8574 25.515 15.4876 25.9832 17.81C26.9192 17.4315 29.0578 15.0075 29.231 13.3989C30.4263 15.2895 29.8689 17.5258 29.1925 19.0385C29.229 19.0473 29.2713 19.0578 29.3188 19.0695C29.9466 19.225 31.476 19.6039 32.3473 19.1903C32.3466 19.2157 32.3459 19.241 32.3451 19.2663Z"
          fill="#F0F0F0"
        />
      </g>
    </svg>
  );
}
