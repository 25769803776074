import { CircularProgressbar } from 'react-circular-progressbar';
import styled from 'styled-components';
import 'react-circular-progressbar/dist/styles.css';
import { formatTimerTime } from '../utils';
import { useEffect, useState } from 'react';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2px;
  background: rgba(210, 60, 60, 0.2);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);

  .CircularProgressbar-trail {
    stroke: transparent;
  }

  .CircularProgressbar-path {
    stroke: #d23c3c;
    transition: linear 1s;
  }

  .CircularProgressbar-text {
    fill: #fff;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: -0.36px;
  }
`;

interface TimerProps {
  totalSeconds: number;
  secondsLeft: number;
}

export function Timer({ totalSeconds, secondsLeft }: TimerProps) {
  if (secondsLeft === 0) return null;
  return (
    <Wrapper>
      <CircularProgressbar
        value={secondsLeft}
        maxValue={totalSeconds}
        text={formatTimerTime(secondsLeft)}
        strokeWidth={4}
      />
    </Wrapper>
  );
}
