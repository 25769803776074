import styled from 'styled-components';
import { IconCoinDarknet } from '../icons';
import { Avatar } from './avatar';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid #252525;

  &:last-child {
    border-bottom: none;
  }
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Name = styled.div`
  font-size: 14px;
  font-weight: 800;
  letter-spacing: -0.42px;
`;

const Rank = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  label {
    display: block;
    color: #909090;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: -0.3px;
  }

  span {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.36px;
  }
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.42px;
  }
`;

export function Member() {
  return (
    <Wrapper>
      <Icon>
        <Avatar />
      </Icon>
      <Name>Cyber Abraham</Name>
      <Rank>
        <label>Rank</label>
        <span>5 / 56</span>
      </Rank>
      <Count>
        <IconCoinDarknet color={'white'} />
        <span>+0.0015</span>
      </Count>
    </Wrapper>
  );
}
