import styled from 'styled-components';
import { BrutButton, Container, InfoCard, Scroll } from '../components';
import { IconGame, IconMackbookMain, IconRank } from '../icons';
import { useState } from 'react';
import { OfflineProfitModal } from '../components/modals';
import { useStore } from 'effector-react';
import { $gameStore } from '../stores';
import { MiniGame } from '../components/mini-game';
import { Link } from 'react-router-dom';
import { triggerHaptic } from '../utils';

const InfoSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
`;

const MackSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 32px 0;
  padding: 0 8px;

  svg {
    max-width: 100%;
  }
`;

export function MainPage() {
  const game = useStore($gameStore);
  const [isGame, setIsGame] = useState(false);

  return (
    <>
      <InfoSection>
        {game && <InfoCard label={'Games left'} icon={<IconGame />} value={game.games_left} />}
        <Link to={'./ranks'} onClick={triggerHaptic}>
          <InfoCard label={'Your rank'} icon={<IconRank />} value={0} />
        </Link>
      </InfoSection>

      <Scroll>
        <MackSection>
          <IconMackbookMain />
        </MackSection>
        <BrutButton onClick={() => setIsGame(true)} />
      </Scroll>

      {isGame && <MiniGame onClose={() => setIsGame(false)} />}
    </>
  );
}
