import { AxiosResponse } from 'axios';
import { $api } from '..';
import { Paginated, RewardDto } from '../../types';

const basePath = '/rewards';

export async function getRewards(
  page = 1,
  size = 10
): Promise<AxiosResponse<Paginated<RewardDto>>> {
  return $api.get(`${basePath}/`, { params: { page, size } });
}
