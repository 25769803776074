import { ReactNode } from 'react';
import styled from 'styled-components';

const Content = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
`;

const TopShadow = styled.div`
  position: relative;
  z-index: 50;
  width: 100%;
  height: 32px;
  margin-bottom: -16px;
  background: linear-gradient(to bottom, #000, transparent);
`;

const BottomShadow = styled.div`
  position: relative;
  z-index: 50;
  width: 100%;
  height: 32px;
  margin-top: -16px;
  background: linear-gradient(to bottom, transparent, #000);
`;

interface ScrollProps {
  children: ReactNode;
}

export function Scroll({ children }: ScrollProps) {
  return (
    <>
      <TopShadow />
      <Content>{children}</Content>
      <BottomShadow />
    </>
  );
}
