'use client';

import ReactDOM from 'react-dom/client';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 16px;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(16px);
  background-color: rgba(255, 255, 255, 0.2);

  font-size: 12px;
  font-weight: 600;
`;

const Close = styled.div`
  position: relative;
  width: 16px;
  height: 16px;

  &::before,
  &::after {
    position: absolute;
    left: 6px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: #fff;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

// const Icon = styled.div`
//   width: 16px;
//   min-width: 16px;
//   height: 16px;

//   svg {
//     width: 16px;
//     height: 16px;
//   }
// `;

const ErrorWrapper = styled(Wrapper)`
  grid-template-columns: 1fr 16px;
  background-color: rgba(252, 72, 72, 0.4);
`;

export const toast = {
  success: (text?: string) => {
    const target = document.getElementById('toasts-container') as HTMLElement;
    let isClose = false;

    const hanldeClose = () => {
      isClose = true;
      target.removeChild(toastDiv);
    };

    const toastDiv = document.createElement('div');
    ReactDOM.createRoot(toastDiv).render(
      <Wrapper>
        {/* <Icon>
          <IconCheck />
        </Icon> */}
        <div className="ml-3 text-sm font-normal mr-3">{text ?? 'Успех!'}</div>
        <Close onClick={hanldeClose} />
      </Wrapper>
    );

    target.appendChild(toastDiv);

    setTimeout(() => {
      if (!isClose) target.removeChild(toastDiv);
    }, 3000);
  },

  error: (text?: string) => {
    const target = document.getElementById('toasts-container') as HTMLElement;
    let isClose = false;

    const hanldeClose = () => {
      isClose = true;
      target.removeChild(toastDiv);
    };

    const toastDiv = document.createElement('div');
    ReactDOM.createRoot(toastDiv).render(
      <ErrorWrapper>
        <div className="ml-3 text-sm font-normal mr-3">{text ?? 'Ошибка!'}</div>
        <Close onClick={hanldeClose} />
      </ErrorWrapper>
    );

    target.appendChild(toastDiv);

    setTimeout(() => {
      if (!isClose) target.removeChild(toastDiv);
    }, 3000);
  },
};
