import styled from 'styled-components';
import { Avatar } from './avatar';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 44px;
  border-bottom: 1px solid #252525;

  &:last-child {
    border-bottom: none;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
`;

const Number = styled.div`
  margin-right: 16px;
  font-size: 14px;
  font-weight: 800;
`;

const Name = styled.div`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: -0.42px;
`;

const Points = styled.div`
  font-size: 20px;
  font-weight: 800;
  letter-spacing: -0.6px;
`;

export function Rank() {
  return (
    <Wrapper>
      <Info>
        <Number>1</Number>
        <Avatar />
        <Name>Name</Name>
      </Info>

      <Points>236 123</Points>
    </Wrapper>
  );
}

const YourWrapper = styled(Wrapper)`
  height: 64px;
  padding: 10px;
  border: 1px solid #d23c3c;
  border-radius: 8px;

  &:last-child {
    border-bottom: 1px solid #d23c3c;
  }
`;

export function YourRank() {
  return (
    <YourWrapper>
      <Info>
        <Number>1</Number>
        <Avatar />
        <Name>Name</Name>
      </Info>

      <Points>236 123</Points>
    </YourWrapper>
  );
}
