import styled from 'styled-components';
import { IconCoinDarknet } from '../icons';
import { useUnit } from 'effector-react';
import { $gameStore } from '../stores';
import { AnimatedBalance } from '../animation';

export const CoinsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 24px;
  font-weight: 700;
`;

export function Coins() {
  const game = useUnit($gameStore);

  if (!game) return null;
  return (
    <CoinsWrapper>
      <IconCoinDarknet />
      <AnimatedBalance start={0} finish={game.current_balance} />
    </CoinsWrapper>
  );
}
