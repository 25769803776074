import { ReactNode, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  targetId: string;
  children: ReactNode;
  el?: string;
}

export function Portal({ children, targetId, el = 'div' }: PortalProps) {
  const target = useMemo(() => document.getElementById(targetId), [targetId]) as HTMLElement;
  const container = useMemo(() => document.createElement(el), []);

  useEffect(() => {
    target.appendChild(container);
    return () => {
      target.removeChild(container);
    };
  }, []);

  return ReactDOM.createPortal(children, container);
}
