import styled from 'styled-components';
import { triggerHaptic } from '../../utils';
import { useEffect, useState } from 'react';
import { MiniGameLetters } from './mini-game-letters';
import { IconArrow } from '../../icons';
import { IconLogo } from '../../icons/icon-logo';
import { UnknownFunction } from '../../types';

type ArrowDirection = 'up' | 'down' | 'left' | 'right';

const PanelWrapper = styled.div`
  border: 1px solid #404040;
  border-radius: 8px;
  padding: 14px;
  background-color: #111;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
`;

interface ButtonProps {
  arrowDirection: ArrowDirection;
}

const Button = styled.button<ButtonProps>`
  position: relative;
  width: 100%;
  padding-top: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(64, 64, 64, 1);
  border-radius: 10px;
  background: linear-gradient(rgba(64, 64, 64, 1), rgba(34, 34, 34, 1));
  box-shadow: 0 4px 2px -2px #000;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: ${({ arrowDirection }) => {
      if (arrowDirection === 'right') return 'rotate(90deg)';
      if (arrowDirection === 'down') return 'rotate(180deg)';
      if (arrowDirection === 'left') return 'rotate(270deg)';
      return 'rotate(0deg)';
    }};
  }
`;

const Enter = styled.button`
  grid-row: span 2 / auto;
  border: 1px solid rgba(240, 90, 90, 1);
  border-radius: 10px;
  background: linear-gradient(#d23c3c, #6c1f1f);
  box-shadow: 0 4px 2px -2px rgba(59, 10, 10);
  color: #fff;
  font-size: 20px;
`;

const EmptyButton = styled.div`
  margin: 10px;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 3px,
    #252525 3px,
    #252525 6px
  );
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  margin: 2px;
  background-color: #000;

  label {
    font-size: 12px;
    font-weight: 800;

    span {
      color: #d23c3c;
    }
  }
`;

export enum FinishButtons {
  exit = 'exit',
  tryAgain = 'tryAgain',
}

interface MiniGameKeyboardProps {
  letters: string[];
  wrongSymbols: string[];
  isPassed: boolean;
  isExpired: boolean;
  newGame: UnknownFunction;
  setCurrentSymbol: (symbol: string) => void;
  onEnter: (symbol: string) => void;
  onClose: UnknownFunction;
}

export function MiniGameKeyboard({
  letters,
  wrongSymbols,
  isPassed,
  isExpired,
  newGame,
  setCurrentSymbol,
  onEnter,
  onClose,
}: MiniGameKeyboardProps) {
  const [activeBtn, setActiveBtn] = useState(FinishButtons.tryAgain);

  const lettersMatrix: string[][] = getMatrix(letters);

  function getMatrix(letters: string[]) {
    if (letters.length !== 10) {
      throw new Error('Массив должен содержать ровно 10 элементов.');
    }
    const matrix: string[][] = [letters.slice(0, 5), letters.slice(5, 10)];
    return matrix;
  }

  const [activeRow, setActiveRow] = useState(0);
  const [activeCol, setActiveCol] = useState(0);

  const moveActive = (direction: ArrowDirection) => {
    triggerHaptic();
    switch (direction) {
      case 'up':
        setActiveRow((prevActiveRow) => (prevActiveRow > 0 ? prevActiveRow - 1 : 0));
        break;
      case 'down':
        setActiveRow((prevActiveRow) =>
          prevActiveRow < lettersMatrix.length - 1 ? prevActiveRow + 1 : lettersMatrix.length - 1
        );
        break;
      case 'left':
        if (isExpired || isPassed) setActiveBtn(FinishButtons.exit);
        setActiveCol((prevActiveCol) => (prevActiveCol > 0 ? prevActiveCol - 1 : 0));
        break;
      case 'right':
        if (isExpired || isPassed) setActiveBtn(FinishButtons.tryAgain);
        setActiveCol((prevActiveCol) =>
          prevActiveCol < lettersMatrix[0].length - 1
            ? prevActiveCol + 1
            : lettersMatrix[0].length - 1
        );
        break;
    }
  };

  const handleEnter = async () => {
    triggerHaptic();
    if (isExpired || isPassed) {
      if (activeBtn === FinishButtons.exit) onClose();
      if (activeBtn === FinishButtons.tryAgain) newGame();
    } else {
      const symbol = lettersMatrix[activeRow][activeCol];
      onEnter(symbol);
    }
  };

  useEffect(() => {
    setCurrentSymbol(lettersMatrix[activeRow][activeCol]);
  }, [activeRow, activeCol]);

  return (
    <PanelWrapper>
      <MiniGameLetters
        activeBtn={activeBtn}
        letters={lettersMatrix}
        wrongSymbols={wrongSymbols}
        isPassed={isPassed}
        isExpired={isExpired}
        activeRow={activeRow}
        activeCol={activeCol}
        newGame={newGame}
        onClose={onClose}
      />

      <Wrapper>
        <EmptyButton>
          <LogoSection>
            <IconLogo />
            <label>
              <span>bf</span>3000
            </label>
          </LogoSection>
        </EmptyButton>
        <Button onClick={() => moveActive('up')} arrowDirection={'up'}>
          <IconArrow />
        </Button>
        <EmptyButton />
        <Enter onClick={handleEnter}>enter</Enter>

        <Button onClick={() => moveActive('left')} arrowDirection={'left'}>
          <IconArrow />
        </Button>
        <Button onClick={() => moveActive('down')} arrowDirection={'down'}>
          <IconArrow />
        </Button>
        <Button onClick={() => moveActive('right')} arrowDirection={'right'}>
          <IconArrow />
        </Button>
      </Wrapper>
    </PanelWrapper>
  );
}
