import { Container, Rank, Scroll, YourRank } from '../components';

export function RanksPage() {
  return (
    <>
      <Scroll>
        <Container>
          <Rank />
          <Rank />
        </Container>
      </Scroll>
      <Container>
        <YourRank />
      </Container>
    </>
  );
}
