import { AxiosResponse } from 'axios';
import { $api } from '..';
import { Paginated, UpgradeDto } from '../../types';

const basePath = '/upgrades';

export async function getUpgrades(
  page = 1,
  size = 10
): Promise<AxiosResponse<Paginated<UpgradeDto>>> {
  return $api.get(`${basePath}/`, { params: { page, size } });
}

export async function purchaseUpgrade(id: string): Promise<AxiosResponse<UpgradeDto>> {
  return $api.post(`${basePath}/${id}/`);
}
