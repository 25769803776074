export function IconYozh() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M26.9542 16.0553C26.9845 15.7075 27 15.3556 27 15C27 8.37259 21.6274 3 15 3C8.37259 3 3 8.37259 3 15C3 21.6274 8.37259 27 15 27C16.0319 27 17.0333 26.8698 17.9888 26.6248C16.6026 26.2977 14.6757 25.8283 13.8794 25.5652C13.0106 25.278 11.6995 24.4901 11.0011 23.7754C10.8668 23.6784 10.737 23.5776 10.6189 23.4695C10.256 23.1348 9.1907 21.7179 10.1201 21.4693C10.2659 21.4308 10.4112 21.4817 10.5635 21.5351C10.7238 21.5912 10.8918 21.6501 11.0767 21.6106C11.3821 21.5452 11.6853 21.3887 11.9476 21.2179C12.4563 20.8869 12.8633 20.411 13.1817 19.8864C13.4616 19.425 13.982 18.5451 13.9459 17.9611C13.9459 17.9611 13.8576 16.5407 12.5714 15.2174C13.1051 15.1913 15.1827 15.8065 15.7897 16.1669C16.093 14.4562 14.6083 12.2606 14.1317 11.7509C15.0102 11.7803 17.5698 13.2847 18.6965 15.0862C19.2576 14.3663 19.5103 11.3207 19.277 9.75046C20.6845 10.7145 21.2625 12.9063 21.6527 14.8417C22.4327 14.5263 24.2149 12.5063 24.3592 11.1658C25.3553 12.7413 24.8908 14.6048 24.3271 15.8655C24.3575 15.8728 24.3928 15.8815 24.4323 15.8913C24.9555 16.0209 26.23 16.3366 26.9561 15.9919C26.9555 16.0131 26.9549 16.0342 26.9542 16.0553Z"
        fill="#F0F0F0"
      />
    </svg>
  );
}
