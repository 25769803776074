export function IconMackbook() {
  return (
    <svg
      width="376"
      height="228"
      viewBox="0 0 376 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M356.573 227.111C357.078 227.009 357.414 226.561 357.519 226.349H332.463C332.568 226.561 332.904 227.009 333.408 227.111C333.461 227.407 333.692 227.999 334.196 227.999H355.785C356.29 227.999 356.521 227.407 356.573 227.111Z"
        fill="#252525"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M356.551 227.214V227.174H333.422C333.494 227.484 333.727 227.999 334.197 227.999H355.786C356.236 227.999 356.469 227.526 356.551 227.214Z"
        fill="black"
        fillOpacity="0.3"
      />
      <path
        d="M42.4151 227.111C42.9194 227.009 43.2555 226.561 43.3606 226.349H18.3047C18.4097 226.561 18.7459 227.009 19.2502 227.111C19.3027 227.407 19.5338 227.999 20.0381 227.999H41.6272C42.1314 227.999 42.3626 227.407 42.4151 227.111Z"
        fill="#252525"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.392 227.214V227.175H19.2632C19.3351 227.484 19.5678 227.999 20.0379 227.999H41.627C42.0776 227.999 42.3101 227.526 42.392 227.214Z"
        fill="black"
        fillOpacity="0.3"
      />
      <mask id="mask0_2001_2309" maskUnits="userSpaceOnUse" x="0" y="217" width="376" height="10">
        <path
          d="M376 218.093V217.104H0V218.093C0 220.983 0 222.428 0.808847 223.531C1.52033 224.502 2.6556 225.292 4.05197 225.787C5.63941 226.349 7.71751 226.349 11.8737 226.349H364.126C368.283 226.349 370.361 226.349 371.948 225.787C373.344 225.292 374.48 224.502 375.191 223.531C376 222.428 376 220.983 376 218.093Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2001_2309)">
        <g filter="url(#filter0_i_2001_2309)">
          <path
            d="M376 218.093V217.104H0V218.093C0 220.983 0 222.428 0.808847 223.531C1.52033 224.502 2.6556 225.292 4.05197 225.787C5.63941 226.349 7.71751 226.349 11.8737 226.349H364.126C368.283 226.349 370.361 226.349 371.948 225.787C373.344 225.292 374.48 224.502 375.191 223.531C376 222.428 376 220.983 376 218.093Z"
            fill="#252525"
          />
        </g>
      </g>
      <g opacity="0.3" filter="url(#filter1_i_2001_2309)">
        <path
          d="M154.193 217.104H221.807C221.807 219.657 219.737 221.726 217.184 221.726H158.816C156.263 221.726 154.193 219.657 154.193 217.104Z"
          fill="black"
        />
      </g>
      <path
        d="M345.491 11.22C345.491 5.02336 340.467 0 334.271 0H42.0581C35.8615 0 30.8381 5.02338 30.8381 11.22L30.8381 210.5C30.8381 212.481 31.168 217.104 33.3118 217.104H343.017C345.161 217.104 345.491 212.481 345.491 210.5V11.22Z"
        fill="#252525"
      />
      <mask id="mask1_2001_2309" maskUnits="userSpaceOnUse" x="30" y="206" width="316" height="12">
        <path
          d="M30.8381 217.103V210.5C30.8381 208.494 32.4643 206.867 34.4703 206.867H341.859C343.865 206.867 345.491 208.494 345.491 210.5V217.103H343.017H33.3118H30.8381Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask1_2001_2309)">
        <g filter="url(#filter2_f_2001_2309)">
          <path
            d="M341.533 217.103C344.609 216.223 344.368 211.305 344.333 208.713C344.327 208.245 343.941 207.845 343.484 207.947C339.262 208.887 340.762 216.948 338.07 217.103H341.533Z"
            fill="#1C1C1C"
          />
        </g>
        <g filter="url(#filter3_f_2001_2309)">
          <path
            d="M34.8105 217.103C31.7343 216.223 31.9761 211.305 32.0108 208.713C32.017 208.245 32.4032 207.845 32.8601 207.947C37.0822 208.887 35.582 216.948 38.2737 217.103H34.8105Z"
            fill="#1C1C1C"
          />
        </g>
      </g>
      <path
        d="M334.273 1.98096H42.0584C36.9552 1.98096 32.8184 6.11785 32.8184 11.221V210.833C32.8184 213.749 35.1823 216.113 38.0984 216.113H338.233C341.149 216.113 343.513 213.749 343.513 210.833V11.221C343.513 6.11785 339.376 1.98096 334.273 1.98096Z"
        fill="#323232"
      />
      <path
        d="M338.236 217.103C341.146 217.103 343.505 214.463 343.513 211.203V207.858H32.8184V211.186C32.8184 214.454 35.181 217.103 38.0956 217.103H338.236Z"
        fill="url(#paint0_linear_2001_2309)"
      />
      <g filter="url(#filter4_f_2001_2309)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M334.277 0.990723H42.0522C36.4053 0.990723 31.8276 5.57356 31.8276 11.2268V210.83C31.8276 214.295 34.6333 217.104 38.0943 217.104H66.789V216.773C66.789 216.409 66.4935 216.113 66.129 216.113H38.0943C35.1798 216.113 32.8171 213.748 32.8171 210.83V11.2268C32.8171 6.12064 36.9518 1.98131 42.0522 1.98131H334.277C339.377 1.98131 343.512 6.12064 343.512 11.2268V210.83C343.512 213.748 341.149 216.113 338.235 216.113H310.2C309.835 216.113 309.54 216.409 309.54 216.773V217.104H338.235C341.696 217.104 344.501 214.295 344.501 210.83V11.2268C344.501 5.57356 339.924 0.990723 334.277 0.990723Z"
          fill="#1C1C1C"
        />
      </g>
      <g clipPath="url(#clip0_2001_2309)">
        <path
          d="M38.0942 10.399C38.0942 8.21192 39.8672 6.43896 42.0542 6.43896H334.275C336.462 6.43896 338.235 8.21192 338.235 10.399V201.75H38.0942V10.399Z"
          fill="white"
        />
        <path d="M38.1001 6H338.24V201.311H38.1001V6Z" fill="black" />
      </g>
      <path
        d="M166.562 6.43896H168.98C169.526 6.43896 169.97 6.8822 169.97 7.42896V11.0629C169.97 12.1564 170.856 13.0429 171.95 13.0429H204.546C205.639 13.0429 206.526 12.1564 206.526 11.0629V7.42896C206.526 6.8822 206.969 6.43896 207.516 6.43896H209.933H166.562Z"
        fill="#323232"
      />
      <rect
        x="184.1"
        y="5.84619"
        width="2.61085"
        height="8.70283"
        transform="rotate(-45 184.1 5.84619)"
        fill="#D23C3C"
      />
      <rect
        x="190.254"
        y="4"
        width="2.61085"
        height="8.70283"
        transform="rotate(45 190.254 4)"
        fill="#D23C3C"
      />
      <defs>
        <filter
          id="filter0_i_2001_2309"
          x="0"
          y="214.511"
          width="376"
          height="11.8375"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-2.805" />
          <feGaussianBlur stdDeviation="1.29607" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2001_2309" />
        </filter>
        <filter
          id="filter1_i_2001_2309"
          x="154.193"
          y="217.104"
          width="67.614"
          height="5.65966"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.03685" />
          <feGaussianBlur stdDeviation="1.29607" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2001_2309" />
        </filter>
        <filter
          id="filter2_f_2001_2309"
          x="337.41"
          y="207.271"
          width="7.60125"
          height="10.4921"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.33" result="effect1_foregroundBlur_2001_2309" />
        </filter>
        <filter
          id="filter3_f_2001_2309"
          x="31.3324"
          y="207.271"
          width="7.60125"
          height="10.4921"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.33" result="effect1_foregroundBlur_2001_2309" />
        </filter>
        <filter
          id="filter4_f_2001_2309"
          x="31.6626"
          y="0.825723"
          width="313.004"
          height="216.443"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="0.0825" result="effect1_foregroundBlur_2001_2309" />
        </filter>
        <linearGradient
          id="paint0_linear_2001_2309"
          x1="188.166"
          y1="207.858"
          x2="188.166"
          y2="217.103"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#323232" stopOpacity="0" />
          <stop offset="1" stopColor="#343434" />
        </linearGradient>
        <clipPath id="clip0_2001_2309">
          <path
            d="M38.0942 10.399C38.0942 8.21192 39.8672 6.43896 42.0542 6.43896H334.275C336.462 6.43896 338.235 8.21192 338.235 10.399V201.75H38.0942V10.399Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
