import { createEvent, createStore } from 'effector';
import { UpgradeDto } from '../types';

export const $upgradesStore = createStore<UpgradeDto[]>([]);

export const eventSetUpgrades = createEvent<UpgradeDto[]>();
$upgradesStore.on(eventSetUpgrades, (_, payload) => payload);

export const eventUpdateUpgrade = createEvent<UpgradeDto>();
$upgradesStore.on(eventUpdateUpgrade, (state, payload) => {
  return state.map((upgrade) => (upgrade.id === payload.id ? { ...upgrade, ...payload } : upgrade));
});
