import styled from 'styled-components';
import { UnknownFunction } from '../../types';
import { Button, ButtonsSection, Modal } from '../UI';

const Description = styled.div`
  max-width: 260px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
`;

interface ExitMiniGameModalProps {
  onClose: UnknownFunction;
  onGameClose: UnknownFunction;
}

export function ExitMiniGameModal({ onClose, onGameClose }: ExitMiniGameModalProps) {
  return (
    <Modal
      onClose={onClose}
      bottomSection={
        <ButtonsSection>
          <Button colorEffect={'blue'} bgColor={'#000'} onClick={onClose}>
            NO
          </Button>
          <Button bgColor={'#000'} onClick={onGameClose}>
            YES
          </Button>
        </ButtonsSection>
      }>
      <Description>Do you want to exit brute force?</Description>
    </Modal>
  );
}
