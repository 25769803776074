export function IconArrow() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 33.75C19.3096 33.75 18.75 33.1904 18.75 32.5V10.5178L9.63389 19.6339C9.14573 20.122 8.35427 20.122 7.86612 19.6339C7.37796 19.1457 7.37796 18.3543 7.86612 17.8661L19.1161 6.61612C19.6043 6.12796 20.3957 6.12796 20.8839 6.61612L32.1339 17.8661C32.622 18.3543 32.622 19.1457 32.1339 19.6339C31.6457 20.122 30.8543 20.122 30.3661 19.6339L21.25 10.5178V32.5C21.25 33.1904 20.6904 33.75 20 33.75Z"
        fill="#F0F0F0"
      />
    </svg>
  );
}
