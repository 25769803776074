import { useSpring, animated } from 'react-spring';

interface AnimatedNumberProps {
  start: number;
  finish: number;
}

export function AnimatedNumber({ start, finish }: AnimatedNumberProps) {
  const props = useSpring({ val: finish, from: { val: start } });

  return <animated.span>{props.val.to((val) => Math.floor(val))}</animated.span>;
}
