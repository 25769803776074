import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Container } from './layout-components';
import { IconHacking, IconTasks, IconTeam, IconUpgrade, IconYozh } from '../../icons';
import { triggerHaptic } from '../../utils';

const NavWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 30px;
`;

const Links = styled.nav`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;

  width: 100%;
  height: 60px;
  border-radius: 12px;
  font-size: 11px;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;

    width: 100%;
    height: 100%;
    text-align: center;
    font-weight: 500;
    color: #fff;

    &.active {
      color: #d23c3c;

      svg path {
        fill: #d23c3c;
      }
    }
  }
`;

const IconLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;
`;

export function Nav() {
  return (
    <NavWrapper>
      <Container>
        <Links>
          <NavLink to={'/'} onClick={triggerHaptic}>
            <IconLink>
              <IconHacking />
            </IconLink>
            Hacking
          </NavLink>
          <NavLink to={'/upgrade'} onClick={triggerHaptic}>
            <IconLink>
              <IconUpgrade />
            </IconLink>
            Upgrade
          </NavLink>
          <NavLink to={'/team'} onClick={triggerHaptic}>
            <IconLink>
              <IconTeam />
            </IconLink>
            Team
          </NavLink>
          <NavLink to={'/tasks'} onClick={triggerHaptic}>
            <IconLink>
              <IconTasks />
            </IconLink>
            Tasks
          </NavLink>
          <NavLink to={'/reward'} onClick={triggerHaptic}>
            <IconLink>
              <IconYozh />
            </IconLink>
            Reward
          </NavLink>
        </Links>
      </Container>
    </NavWrapper>
  );
}
