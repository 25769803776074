import styled from 'styled-components';
import { Button } from '../UI';
import { UnknownFunction } from '../../types';
import { FinishButtons } from './mini-game-keyboard';

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  margin-bottom: 12px;
`;

interface LetterProps {
  isActive: boolean;
  isWrong: boolean;
}

const Letter = styled.div<LetterProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  border: ${({ isActive, isWrong }) => {
    if (isActive) return '2px solid #D23C3C';
    if (isWrong) return '2px solid rgba(255, 255, 255, 0.4)';
    return '2px solid #fff';
  }};
  font-weight: 250;
  font-size: 34px;
  color: ${({ isWrong }) => (isWrong ? 'rgba(255, 255, 255, 0.4)' : '#F0F0F0')};
`;

const Triangle = styled.div<LetterProps>`
  position: absolute;
  right: -2px;
  bottom: -2px;
  border: 10px solid transparent;
  border-right: ${({ isActive, isWrong }) => {
    if (isActive) return '10px solid #D23C3C';
    if (isWrong) return '10px solid rgba(255, 255, 255, 0.4)';
    return '10px solid #fff';
  }};
  border-bottom: ${({ isActive, isWrong }) => {
    if (isActive) return '10px solid #D23C3C';
    if (isWrong) return '10px solid rgba(255, 255, 255, 0.4)';
    return '10px solid #fff';
  }};

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: -10px;
    bottom: -10px;
    border: 8px solid transparent;
    border-right: 8px solid #111;
    border-bottom: 8px solid #111;
  }
`;

const Passed = styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #111;
`;

interface MiniGameLettersProps {
  letters: string[][];
  wrongSymbols: string[];
  activeRow: number;
  activeCol: number;
  isPassed: boolean;
  isExpired: boolean;
  activeBtn: FinishButtons;
  newGame: UnknownFunction;
  onClose: UnknownFunction;
}

export function MiniGameLetters({
  letters,
  wrongSymbols,
  activeRow,
  activeCol,
  isPassed,
  isExpired,
  activeBtn,
  newGame,
  onClose,
}: MiniGameLettersProps) {
  return (
    <Wrapper>
      {(isPassed || isExpired) && (
        <Passed>
          <Button
            onClick={onClose}
            colorEffect={activeBtn === FinishButtons.exit ? 'blue' : undefined}>
            Exit
          </Button>
          <Button
            onClick={newGame}
            colorEffect={activeBtn === FinishButtons.tryAgain ? 'blue' : undefined}>
            Try again
          </Button>
        </Passed>
      )}
      {letters.map((row, rowIndex) => (
        <>
          {row.map((letter, colIndex) => (
            <Letter
              key={colIndex}
              isActive={activeRow === rowIndex && activeCol === colIndex}
              isWrong={wrongSymbols.includes(letter)}>
              {letter}
              <Triangle
                isActive={activeRow === rowIndex && activeCol === colIndex}
                isWrong={wrongSymbols.includes(letter)}
              />
            </Letter>
          ))}
        </>
      ))}
    </Wrapper>
  );
}
