export function IconTeam() {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5 8.25C17.1569 8.25 18.5 6.90685 18.5 5.25C18.5 3.59315 17.1569 2.25 15.5 2.25C13.8431 2.25 12.5 3.59315 12.5 5.25C12.5 6.90685 13.8431 8.25 15.5 8.25Z"
        fill="#F0F0F0"
      />
      <path
        d="M10.574 23.008C9.80075 23.8201 8.70086 24.3293 7.48145 24.3293C6.26203 24.3293 5.16214 23.8201 4.38892 23.008C3.4005 23.668 2.75 24.7806 2.75 26.0396V27.017C2.75 27.4218 3.08449 27.75 3.49707 27.75H11.4658C11.8784 27.75 12.2129 27.4218 12.2129 27.017V26.0396C12.2129 24.7806 11.5624 23.668 10.574 23.008ZM26.6111 23.008C25.8379 23.8201 24.738 24.3293 23.5186 24.3293C22.2991 24.3293 21.1993 23.8201 20.426 23.008C19.4376 23.668 18.7871 24.7806 18.7871 26.0396V27.017C18.7871 27.4218 19.1216 27.75 19.5342 27.75H27.5029C27.9155 27.75 28.25 27.4218 28.25 27.017V26.0396C28.25 24.7806 27.5995 23.668 26.6111 23.008ZM20.8153 20.608C21.0267 21.8851 22.1582 22.8633 23.5186 22.8633C25.029 22.8633 26.2578 21.6576 26.2578 20.1756C26.2578 18.6936 25.029 17.4878 23.5186 17.4878C22.3867 17.4878 21.413 18.165 20.996 19.128L17.739 18.1356C17.7031 17.2171 17.0906 16.4414 16.2471 16.1479V12.992H19.4844C19.897 12.992 20.2314 12.6639 20.2314 12.259V11.2817C20.2314 10.0227 19.5809 8.91005 18.5925 8.25C17.8193 9.06218 16.7194 9.57133 15.5 9.57133C14.2806 9.57133 13.1807 9.06218 12.4075 8.25C11.4191 8.91005 10.7686 10.0227 10.7686 11.2817V12.259C10.7686 12.6639 11.103 12.992 11.5156 12.992H14.7529V16.1479C13.9094 16.4414 13.2969 17.217 13.261 18.1356L10.004 19.128C9.58704 18.165 8.61331 17.4878 7.48145 17.4878C5.97102 17.4878 4.74219 18.6936 4.74219 20.1756C4.74219 21.6576 5.97102 22.8633 7.48145 22.8633C8.84181 22.8633 9.97327 21.8851 10.1847 20.608L13.7044 19.5355C14.1135 20.072 14.7661 20.4199 15.5 20.4199C16.2339 20.4199 16.8865 20.072 17.2956 19.5355L20.8153 20.608Z"
        fill="#F0F0F0"
      />
    </svg>
  );
}
