import styled from 'styled-components';
import { triggerHaptic } from '../../utils';
import { UnknownFunction } from '../../types';

export const BrutButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 16px;
`;

const Button = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 252px;
  height: 87px;
  border: none;

  background-color: transparent;
  font-size: 32px;
  color: #d23c3c;
`;

const EffectTop = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  width: 190px;
  height: 50px;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 3px,
    #252525 3px,
    #252525 6px
  );

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background-color: #000;
  }
`;

const EffectBottom = styled.div`
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;

  width: 70px;
  height: 50px;
  background: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 3px,
    #252525 3px,
    #252525 6px
  );

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    background-color: #000;
  }
`;

interface BrutButtonProps {
  onClick: UnknownFunction;
}

export function BrutButton({ onClick }: BrutButtonProps) {
  return (
    <BrutButtonWrapper>
      <Button
        onClick={() => {
          triggerHaptic();
          onClick();
        }}>
        <EffectTop />
        <EffectBottom />
        BRUTEFORCE
      </Button>
    </BrutButtonWrapper>
  );
}
