export function IconInfo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_464_30878)">
        <path
          d="M8 0C3.57841 0 0 3.578 0 8C0 12.4215 3.578 16 8 16C12.4216 16 16 12.422 16 8C16 3.57847 12.422 0 8 0ZM8.82153 11.1757C8.82153 11.4284 8.45297 11.6811 8.00016 11.6811C7.52628 11.6811 7.18934 11.4284 7.18934 11.1757V7.16356C7.18934 6.86872 7.52631 6.66859 8.00016 6.66859C8.45297 6.66859 8.82153 6.86872 8.82153 7.16356V11.1757ZM8.00019 5.69987C7.51578 5.69987 7.13672 5.34184 7.13672 4.94166C7.13672 4.5415 7.51581 4.194 8.00019 4.194C8.47406 4.194 8.85319 4.5415 8.85319 4.94166C8.85319 5.34184 8.47403 5.69987 8.00019 5.69987Z"
          fill="#32C5F3"
        />
      </g>
      <defs>
        <clipPath id="clip0_464_30878">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
