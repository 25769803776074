import styled from 'styled-components';
import { Container } from './layout-components';
import { Coins } from '../coins';
import { IconSetting } from '../../icons';
import { triggerHaptic } from '../../utils';
import { $gameStore } from '../../stores';
import { useUnit } from 'effector-react';
import { useState } from 'react';
import { Settings } from '../settings';
import { AnimatedBalance } from '../../animation';

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr 32px;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 24px 0;
`;

const HeaderLogo = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid #32c5f3;
  border-radius: 100%;
`;

const HeaderCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const HeaderSetting = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;

const HourlyIncome = styled.div`
  display: flex;
  gap: 4px;
  font-size: 12px;
  font-weight: 600;
  color: #32c5f3;
`;

export function Header() {
  const game = useUnit($gameStore);
  const [isSettings, setIsSettings] = useState(false);

  if (!game) return null;
  return (
    <Container>
      <HeaderWrapper>
        <HeaderLogo>
          <img src={'./assets/img/avatar.png'} alt="" />
        </HeaderLogo>
        <HeaderCenter>
          <Coins />
          <HourlyIncome>
            hourly income:{' '}
            <span>
              +<AnimatedBalance start={0} finish={game.hourly_income} />
            </span>
          </HourlyIncome>
        </HeaderCenter>
        <HeaderSetting
          onClick={() => {
            triggerHaptic();
            setIsSettings(true);
          }}>
          <IconSetting />
        </HeaderSetting>
      </HeaderWrapper>

      {isSettings && (
        <Settings
          onClose={() => {
            triggerHaptic();
            setIsSettings(false);
          }}
        />
      )}
    </Container>
  );
}
