import { ChangeEvent, ChangeEventHandler, useState } from 'react';
import styled from 'styled-components';

const ToggleWrapper = styled.label`
  position: relative;
  display: block;
  width: 68px;
  height: 36px;
  border-radius: 999px;
  background-color: #111;
  border: 1px solid #404040;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f0f0f0;
    box-shadow:
      0px 3px 8px rgba(0, 0, 0, 0.15),
      0px 1px 1px rgba(0, 0, 0, 0.16);
    transition: 0.15s;
  }

  &.active {
    background-color: #202020;

    &::before {
      transform: translateX(32px);
      box-shadow:
        0px 0px 8px rgba(0, 0, 0, 0),
        0px 0px 1px rgba(0, 0, 0, 0);
    }
  }

  input {
    display: none;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: -0.36px;
`;

export interface ToggleProps {
  label?: string;
  defaultValue?: boolean;
  onChange?: (value: boolean, e: ChangeEvent<HTMLInputElement>) => void;
}

export function Toggle({ label, defaultValue, onChange }: ToggleProps) {
  const [innerValue, setInnerValue] = useState(defaultValue ?? false);

  const handleToggle: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInnerValue(e.currentTarget.checked);
    if (onChange) onChange(e.currentTarget.checked, e);
  };

  return (
    <div>
      {label && <Label>{label}</Label>}
      <ToggleWrapper className={innerValue ? 'active' : ''}>
        <input type={'checkbox'} checked={innerValue} onChange={handleToggle} />
      </ToggleWrapper>
    </div>
  );
}
