import styled from 'styled-components';
import { RefreshIcon } from '../../icons';
import { Container } from '../layout';
import { Member } from '../member';
import { Scroll } from '../UI';

const Heading = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  align-items: center;

  h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
`;

export function TeamMembers() {
  return (
    <>
      <Container>
        <Heading>
          <div />
          <h3>Team members (4)</h3>
          <Icon>
            <RefreshIcon />
          </Icon>
        </Heading>
      </Container>

      <Scroll>
        <Container>
          <Member />
          <Member />
          <Member />
          <Member />
          <Member />
          <Member />
          <Member />
          <Member />
          <Member />
          <Member />
          <Member />
          <Member />
        </Container>
      </Scroll>
    </>
  );
}
