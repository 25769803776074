import styled from 'styled-components';
import { Button, Scroll } from '../UI';
import { initUtils } from '@telegram-apps/sdk-react';
import { MemberReward } from '../member-reward';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100%;
`;

const Title = styled.h3`
  margin-top: 8px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
`;

const Row = styled.div`
  margin-top: 24px;

  h4 {
    margin-bottom: 16px;
    font-size: 16px;
    text-align: center;
  }
`;

const RowList = styled.div`
  display: flex;
  gap: 26px;
  margin: 0 auto;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 36px;
  width: 100%;

  button {
    margin: 0;
  }
`;

export function TeamRewards() {
  const utils = initUtils();
  const url = `t.me/CyberYozhGameBot/app?startapp=ref_cherkoz`;

  const copyLink = async () => {
    await navigator.clipboard.writeText(url).then(() => alert('Ссылка скопирована!'));
  };

  const invite = () => {
    utils.shareURL(url, 'Hi!');
  };

  return (
    <>
      <Scroll>
        <Content>
          <Title>Invite and get reward</Title>

          <Row>
            <h4>For member</h4>
            <RowList>
              <MemberReward label={'Your reward'} />
              <MemberReward label={'Member’s reward'} />
            </RowList>
          </Row>

          <Row>
            <h4>For telegram premium member</h4>
            <RowList>
              <MemberReward label={'Your reward'} />
              <MemberReward label={'Member’s reward'} />
            </RowList>
          </Row>

          <Row>
            <h4>For each 1</h4>
            <RowList>
              <MemberReward label={'Your additional reward'} />
            </RowList>
          </Row>
        </Content>
      </Scroll>

      <Buttons>
        <Button bgColor={'#000'} colorEffect={'blue'} onClick={copyLink}>
          COPY LINK
        </Button>
        <Button bgColor={'#000'} onClick={invite}>
          INVITE
        </Button>
      </Buttons>
    </>
  );
}
